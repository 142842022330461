import React, { Component } from "react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import PropTypes from "prop-types";
import "./Relatorios.css";
import search from "../../assets/icons/no-results.png";
import PedidoRelatorio from "./PedidoRelatorio";
import { toast } from "react-hot-toast";
import ModalRelatorios from "react-modal";
import ModalRelatorio from "./ModalRelatorio";
import * as PrestAPI from "../../service/api/PrestAPI";

class Relatorios extends Component {
  static propTypes = {
    estabelecimento: PropTypes.object.isRequired,
  };

  state = {
    tabIndex: 0,
    filtrando: false,
    loading: false,
    relatorio_pedidos: [],
    pedidos: [],
    pedido: {},
    itens: [],
    subtotal: 0,
    id: "",
    status: "",
    forma: "",
    inicio: "",
    fim: "",
    ordem: "Menor - Maior",
    totalPedidos: 0,
    isModalVisible: false,
    mediaPedidos: 0,
    mediaValor: 0.0,
    mediaPedCheck: true,
    mediaVrCheck: true,
  };

  componentDidMount = () => {
    PrestAPI.get(
      "relatorio",
      `estab=${this.props.estabelecimento.id_estabelecimento}`
    ).then((data) => {
      this.setState({ relatorio_pedidos: data });
    });
    this.setState({ filtrando: false });
  };

  showModal = (pedido) => {
    PrestAPI.get("Pedidos", `id_pedido=${pedido.id_pedido}`).then((value) => {
      this.setState({ pedido });
      this.setState({ itens: value });
      this.calSubtotal();
    });
    this.setState({ isModalVisible: true });
  };

  hideModal = () => {
    this.setState({ isModalVisible: false });
  };

  calSubtotal = () => {
    const { itens } = this.state;

    const subtotal = itens.reduce((soma, item) => {
      const precoSubItens = item.vr_total_sub_itens || 0;
      const preco = (item.vr_unitario + precoSubItens) * item.quantidade;
      return soma + preco;
    }, 0);

    this.setState({ subtotal });
  };

  setFiltro = () => {
    const { relatorio_pedidos, inicio, fim, ordem } = this.state;

    this.loader();

    if ((inicio && !fim) || (!inicio && fim)) {
      toast.error(
        "É necessário informar uma data inicial e uma data final para o período",
        { id: "toastPedido" }
      );
      this.setState({ pedidos: [] });
      return;
    }

    if (inicio && fim && new Date(inicio) > new Date(fim)) {
      toast.error("A data inicial deve ser menor que a data final", {
        id: "toastPedido",
      });
      this.setState({ pedidos: [] });
      return;
    }

    const pedidos = relatorio_pedidos.filter(
      (pedido) =>
        this.filtroId(pedido) &&
        this.filtroStatus(pedido) &&
        this.filtroForma(pedido) &&
        this.filtroData(pedido)
    );

    if (ordem === "Menor - Maior") {
      pedidos.sort((a, b) => new Date(a.data_hora) - new Date(b.data_hora));
    } else if (ordem === "Maior - Menor") {
      pedidos.sort((a, b) => new Date(b.data_hora) - new Date(a.data_hora));
    }

    this.filterMedia(pedidos);
    this.setTotalPedidos(pedidos);

    this.setState({
      pedidos,
      filtrando: true,
      loading: false,
    });
  };
  filtroId = (pedido) => {
    const { id } = this.state;
    return id ? pedido.id_pedido === parseInt(id) : true;
  };

  filtroStatus = (pedido) => {
    const { status } = this.state;
    return status && status !== "Todos"
      ? pedido.status_pedido === status.toUpperCase()
      : true;
  };

  filtroForma = (pedido) => {
    const { forma } = this.state;
    return forma
      ? pedido.forma_pagamento
          .toLowerCase()
          .includes(forma.toLowerCase().trim())
      : true;
  };

  filtroData = (pedido) => {
    const { inicio, fim } = this.state;
    if (inicio && fim) {
      return (
        new Date(pedido.data_hora) >= new Date(`${inicio} 00:00:00`) &&
        new Date(pedido.data_hora) <= new Date(`${fim} 23:59:59`)
      );
    }
    return true;
  };

  filterMedia = (pedidos) => {
    const { mediaPedCheck, mediaVrCheck } = this.state;
    let dateArr = [];
    let pedidosQty = [];
    let valorQty = [];

    if (pedidos && pedidos.length !== 0) {
      pedidos.forEach((ped) => {
        ped.relatorio_data = new Date(ped.data_hora)
          .toLocaleDateString("pt-BR")
          .replaceAll("/", "");
        dateArr.push(ped);
      });

      let pedidosByDate = this.groupBy(dateArr, "relatorio_data");

      for (let key in pedidosByDate) {
        let pedQty = pedidosByDate[key].length;
        let arrValores = pedidosByDate[key].map((vrPed) => vrPed.vr_pedido);

        let mediaValor = (
          arrValores.reduce((a, b) => a + b, 0) / arrValores.length
        ).toFixed(2);

        pedidosQty.push(pedQty);
        valorQty.push(Number.parseFloat(mediaValor));
      }

      if (mediaPedCheck) {
        let mediaPedidos = Math.trunc(
          pedidosQty.reduce((a, b) => a + b, 0) / pedidosQty.length
        );
        this.setState({ mediaPedidos });
      }

      if (mediaVrCheck) {
        let mediaValor = Number.parseFloat(
          valorQty.reduce((a, b) => a + b, 0) / valorQty.length
        );
        this.setState({ mediaValor });
      }
    }
  };

  groupBy = (objectArray, property) => {
    return objectArray.reduce((accumulator, currentObject) => {
      let key = currentObject[property];
      if (!accumulator[key]) {
        accumulator[key] = [];
      }
      accumulator[key].push(currentObject);
      return accumulator;
    }, {});
  };

  limpaFiltro = () => {
    this.setState({
      filtrando: false,
      loading: true,
      id: "",
      status: "",
      forma: "",
      inicio: "",
      fim: "",
      pedidos: [],
    });

    this.loader();
  };

  setTotalPedidos = (pedidos) => {
    const total = pedidos.reduce(
      (acc, pedido) => acc + (pedido.vr_pedido || 0),
      0
    );
    this.setState({ totalPedidos: parseFloat(total) });
  };

  loader = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false });
    }, 1000);
  };

  render() {
    const {
      tabIndex,
      filtrando,
      loading,
      pedidos,
      pedido,
      itens,
      subtotal,
      isModalVisible,
      totalPedidos,
      mediaPedidos,
      mediaPedCheck,
      mediaValor,
      mediaVrCheck,
    } = this.state;

    return (
      <>
        <Tabs
          selectedIndex={tabIndex}
          onSelect={(tabIndex) => this.setState({ tabIndex })}
        >
          <TabList className="container">
            <Tab>Pedido</Tab>
          </TabList>
          <TabPanel>
            <div className="container-fluid">
              <div className="row g-0">
                <div
                  className="col-3 overflow-auto px-4"
                  style={{
                    backgroundColor: "#F8F8F8",
                    border: "solid #EAEAEA 1px",
                    borderRight: "none",
                    borderRadius: "5px 0px 0px 5px",
                    height: "80vh",
                  }}
                >
                  <div className="col-12 pt-2">
                    <h4 style={{ fontWeight: "bolder" }}>Filtrar por:</h4>
                    <hr />
                  </div>
                  <div className="col-12">
                    <label className="form-label">Id do Pedido:</label>

                    <input
                      className="form-control input-md"
                      type="number"
                      id="idRela"
                      value={this.state.id}
                      onChange={(e) => this.setState({ id: e.target.value })}
                    />
                  </div>

                  <div className="col-12 mb-2">
                    <label className="form-label">Status do Pedido:</label>
                    <select
                      className="form-select"
                      id="statusRela"
                      value={this.state.status}
                      onChange={(e) =>
                        this.setState({ status: e.target.value })
                      }
                    >
                      <option value="Todos">Todos</option>
                      <option value="Pendente">Pendente</option>
                      <option value="Confirmado">Confirmado</option>
                      <option value="Despachado">Despachado</option>
                      <option value="Entregue">Entregue</option>
                      <option value="Cancelado">Cancelado</option>
                    </select>
                  </div>
                  <div className="col-12 mb-2">
                    <label className="form-label">Forma de pagamento:</label>
                    <select
                      className="form-select"
                      id="formaRela"
                      value={this.state.forma}
                      onChange={(e) => this.setState({ forma: e.target.value })}
                    >
                      <option value="">Todos</option>
                      <option value="Dinheiro">Dinheiro</option>
                      <option value="Cartão - Débito">Cartão - Débito</option>
                      <option value="Cartão - Crédito">Cartão - Crédito</option>
                    </select>
                  </div>
                  <div className="col-12">
                    <label className="form-label">De:</label>
                    <input
                      type="date"
                      name="de"
                      id="inicioRela"
                      className="form-control input-md"
                      value={this.state.inicio}
                      onChange={(e) =>
                        this.setState({ inicio: e.target.value })
                      }
                    />
                  </div>

                  <div className="col-12">
                    <label className="form-label">Até:</label>
                    <input
                      type="date"
                      name="ate"
                      id="fimRela"
                      value={this.state.fim}
                      className="form-control input-md"
                      onChange={(e) => this.setState({ fim: e.target.value })}
                    />
                  </div>

                  <div className="col-12 mb-2">
                    <label className="form-label">Ordenar (data):</label>
                    <select
                      className="form-select"
                      onChange={(e) => this.setState({ ordem: e.target.value })}
                      value={this.state.ordem}
                    >
                      <option value="">Padrão</option>
                      <option value="Menor - Maior">Menor - Maior</option>
                      <option value="Maior - Menor">Maior - Menor</option>
                    </select>
                  </div>
                  {/* ----------- Checkboxes ---------- */}

                  <div className="col-12 form-check mb-2">
                    <label className="form-check-label">
                      Média - pedidos diários
                    </label>
                    <input
                      type="checkbox"
                      id="qtde_media"
                      className="form-check-input"
                      style={{ width: "20px", height: "20px" }}
                      defaultChecked
                    />
                  </div>
                  <div className="col-12 form-check mb-2">
                    <label className="form-check-label">
                      Ticket Médio - diário
                    </label>
                    <input
                      type="checkbox"
                      id="valor_media"
                      className="form-check-input"
                      style={{ width: "20px", height: "20px" }}
                      defaultChecked
                    />
                  </div>
                  <div className="col-12">
                    <button
                      className="btn btn-md btn-vk w-100 mt-2"
                      onClick={() => {
                        this.setFiltro();
                      }}
                    >
                      Filtrar Pedidos
                    </button>
                    {filtrando && (
                      <button
                        className="btn btn-outline-vk w-100 mt-2"
                        onClick={() => {
                          this.limpaFiltro();
                        }}
                      >
                        Limpar Filtros
                      </button>
                    )}
                  </div>
                </div>
                <div className="col-9">
                  <div
                    className="col-12"
                    style={{
                      border: "solid #EAEAEA 1px",
                      borderLeft: "none",
                      borderRadius: "0px 5px 0px 0px",
                      height: "7vh",
                      backgroundColor: "#f8f8f8",
                    }}
                  >
                    {filtrando ? (
                      <nav
                        className="m-0"
                        style={{
                          margin: "0px",
                          padding: "20px 115px 20px 100px",
                        }}
                      >
                        <div className="col-12">
                          <div className="row">
                            <div className="col-2">
                              <div className="col-12 text-center">
                                <b>Id:</b>
                              </div>
                            </div>
                            <div
                              className="col-2"
                              style={{
                                borderLeft: "solid 1.5px #000",
                              }}
                            >
                              <div className="col-12 text-center">
                                <b>Cliente:</b>
                              </div>
                            </div>
                            <div
                              className="col-2 text-center"
                              style={{
                                borderLeft: "solid 1.5px #000",
                              }}
                            >
                              <div className="col-12 text-center">
                                <b>Status:</b>
                              </div>
                            </div>
                            <div
                              className="col-2 text-center"
                              style={{
                                borderLeft: "solid 1.5px #000",
                              }}
                            >
                              <div className="col-12 text-center">
                                <b>Data:</b>
                              </div>
                            </div>
                            <div
                              className="col-2"
                              style={{
                                borderLeft: "solid 1.5px #000",
                              }}
                            >
                              <div className="col-12 text-center">
                                <b>Forma:</b>
                              </div>
                            </div>
                            <div
                              className="col-2"
                              style={{
                                borderLeft: "solid 1.5px #000",
                              }}
                            >
                              <div className="col-12 text-center">
                                <b>Total:</b>
                              </div>
                            </div>
                          </div>
                        </div>
                      </nav>
                    ) : null}
                  </div>
                  <div
                    className="col-12"
                    style={{
                      border: "solid #EAEAEA 1px",
                      borderTop: "none",
                      borderRadius: "0px 0px 5px 0px",
                      height: "73vh",
                      padding: "0px",
                    }}
                  >
                    {!loading ? (
                      <>
                        <div
                          className="col-12 overflow-auto"
                          style={{
                            height: "60vh",
                            paddingTop: "30px",
                          }}
                        >
                          <div className="col-12 py-2 px-5 row">
                            {pedidos ? (
                              <>
                                {pedidos.map((pedido) => (
                                  <PedidoRelatorio
                                    pedido={pedido}
                                    hideModal={() => this.hideModal()}
                                    showModal={() => this.showModal(pedido)}
                                  />
                                ))}
                              </>
                            ) : (
                              <div
                                className="col-12 row justify-content-center w-100 h-100"
                                style={{
                                  paddingTop: "20%",
                                }}
                              >
                                <div
                                  className="col text-center"
                                  style={{
                                    color: "#ff5722",
                                    fontWeight: "bold",
                                  }}
                                >
                                  <div className="col-12">
                                    <img
                                      src={search}
                                      alt=" "
                                      width="150px"
                                      height="150px"
                                      style={{
                                        margin: "0 auto !important",
                                      }}
                                    />
                                  </div>
                                  <div className="col-12 text-center">
                                    <h4>
                                      <b>Nenhum pedido encontrado.</b>
                                    </h4>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        {pedidos.length !== 0 && filtrando ? (
                          <>
                            <div className="col-12">
                              <hr className="mb-0" />
                            </div>
                            <div className="col-12">
                              <div
                                className="col-12 row"
                                style={{ marginTop: "15px" }}
                              >
                                <div className="col-3 text-center">
                                  {mediaPedCheck ? (
                                    <div className="col-12">
                                      <h4>
                                        Média - pedidos diários:
                                        <div className="w-100"></div>
                                        <b>{mediaPedidos}</b>
                                      </h4>
                                    </div>
                                  ) : null}
                                </div>
                                <div className="col-3 text-center">
                                  {mediaVrCheck ? (
                                    <div className="col-12">
                                      <h4>
                                        Ticket Médio por Venda
                                        <div className="w-100"></div>
                                        <b>
                                          {new Intl.NumberFormat("pt-BR", {
                                            style: "currency",
                                            currency: "BRL",
                                          }).format(mediaValor)}
                                        </b>
                                      </h4>
                                    </div>
                                  ) : null}
                                </div>
                                <div className="col-3 text-center">
                                  <div className="col-12">
                                    <h4>
                                      Pedidos encontrados:
                                      <div className="w-100"></div>
                                      <b>{pedidos.length}</b>
                                    </h4>
                                  </div>
                                </div>
                                <div className="col-3 text-center">
                                  <div className="col-12">
                                    <h4>
                                      Valor total:
                                      <div
                                        className="w-100"
                                        style={{ marginTop: "10px" }}
                                      ></div>
                                      <b>
                                        {new Intl.NumberFormat("pt-BR", {
                                          style: "currency",
                                          currency: "BRL",
                                        }).format(totalPedidos)}
                                      </b>
                                    </h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : null}
                      </>
                    ) : (
                      <div className="loaderRela" id="loader">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
        </Tabs>
        <ModalRelatorios
          ariaHideApp={false}
          isOpen={isModalVisible}
          style={customStyles}
          contentLabel="Relatório Pedido"
          onRequestClose={() => this.hideModal()}
        >
          <ModalRelatorio pedido={pedido} itens={itens} subtotal={subtotal} />
        </ModalRelatorios>
      </>
    );
  }
}

export default Relatorios;

const customStyles = {
  content: {
    trasnition: "0.2s",
    inset: "0px 0px 0px 45%",
    borderRadius: "5px 0px 0px 5px",
  },
  overlay: {
    zIndex: 2,
    position: "fixed",
    backgroundColor: "rgba(0.30, 0.20, 0, 0.20)",
  },
};
