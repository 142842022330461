import React, { useState, useEffect } from "react";
import Perfil from "../Perfil/Perfil";
import Empresas from "../Estabelecimento/Estabelecimentos";
import FormCadastro from "../Estabelecimento/FormCadastro";
import Planos from "../Planos/Planos";
import CryptoJS from "crypto-js";
import Usuarios from "../Usuarios/Usuarios";

const Config = () => {
  const [activeTab, setActiveTab] = useState("perfil");
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    const getUserRole = () => {
      const loggedDados = localStorage.getItem("Estab");

      if (!loggedDados) {
        console.log("Nenhum dado encontrado no local storage.");
        return "";
      }

      try {
        const decryptDados = CryptoJS.AES.decrypt(
          loggedDados,
          process.env.REACT_APP_ENCRYPT_PASS
        );
        const decryptedDados = JSON.parse(
          decryptDados.toString(CryptoJS.enc.Utf8)
        );
        return decryptedDados.role || "";
      } catch (error) {
        console.error("Erro ao descriptografar os dados", error);
        return "";
      }
    };

    const role = getUserRole();
    setUserRole(role);
  }, []);

  const renderTabContent = () => {
    switch (activeTab) {
      case "perfil":
        return <Perfil />;
      case "cadastro":
        return <FormCadastro />;
      case "usuarios":
        return <Usuarios />;
      case "empresas":
        return <Empresas />;
      case "planos":
        return <Planos />;
      case "ajuda":
        return <div>Ajuda Content</div>;
      default:
        return null;
    }
  };

  const renderTab = (tab, label, allowedRoles = []) => {
    if (allowedRoles.length > 0 && !allowedRoles.includes(userRole)) {
      return null;
    }

    return (
      <li className="nav-item" role="presentation">
        <button
          className={`nav-link cursor-pointer ${
            activeTab === tab ? "active" : "text-dark"
          }`}
          onClick={() => setActiveTab(tab)}
        >
          {label}
        </button>
      </li>
    );
  };

  return (
    <div className="container-fluid container">
      <div className="my-4">
        <h2 className="text-center" style={{ color: "#FF5722" }}>
          Configurações
        </h2>
        <ul className="nav nav-tabs">
          {renderTab("perfil", "Perfil")}
          {renderTab("cadastro", "Meus dados")}
          {renderTab("usuarios", "Usuarios")}
          {renderTab("empresas", "Empresas", ["admin", "SUP"])}
          {renderTab("planos", "Planos", ["admin", "SUP"])}
          {renderTab("ajuda", "Ajuda")}
        </ul>
        <div className="tab-content mt-3">{renderTabContent()}</div>
      </div>
    </div>
  );
};

export default Config;
