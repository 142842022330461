import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ReactLoading from "react-loading";
import { toast } from "react-hot-toast";
import CryptoJS from "crypto-js";
import { withRouter } from "react-router-dom";
import { useAuthEstabelecimento } from "../../hooks/auth/useAuthEstabelecimento";

const Login = ({ login, imagem, history }) => {
  const [loading, setLoading] = useState(true);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { login: loginHook } = useAuthEstabelecimento();

  const handleSubmit = () => {
    if (username !== "" && password !== "") {
      let data = {
        username: username,
        password: password,
      };

      if (login) {
        login(data);
      }
    } else {
      toast.error("Há campos vazios no formulário", {
        id: "erroLogin",
        duration: 1000,
      });
    }
  };

  useEffect(() => {
    const checkStoragedAuth = async () => {
      const params = new URLSearchParams(window.location.search);
      const tipoCadastro = params.get("tipo");

      try {
        let loggedDados = localStorage.getItem("Estab");

        if (loggedDados !== null) {
          let decryptDados = CryptoJS.AES.decrypt(
            loggedDados,
            process.env.REACT_APP_ENCRYPT_PASS
          );
          let decryptedDados = JSON.parse(
            decryptDados.toString(CryptoJS.enc.Utf8)
          );

          const filtroDados = {
            username: decryptedDados.uid,
            password: decryptedDados.ticket,
          };
          await login(filtroDados);
        } else {
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    checkStoragedAuth();
  }, []);

  return (
    <>
      {!loading ? (
        <div
          className="container-fluid mx-auto p-5 rounded-2 mt-5 w-100 bg-white"
          style={{ maxWidth: "800px" }}
        >
          <div className="row justify-content-center py-5">
            <div className="col-sm-8">
              <div className="col justify-content-center d-flex">
                <img
                  className="imagem-login mt-2 mx-auto"
                  src={imagem}
                  alt="..."
                />
              </div>
              <div className="col">
                <label className="form-label">Usuário:</label>
                <input
                  name="cnpj"
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="Digite o usuário"
                  required
                  style={{ marginBottom: "16px" }}
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit();
                    }
                  }}
                />
              </div>
              <div className="col">
                <label className="form-label">Senha:</label>
                <input
                  name="senha"
                  type="password"
                  className="form-control form-control-lg mt-2"
                  placeholder="Digite sua senha"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit();
                    }
                  }}
                />
              </div>

              <button
                className="btn btn-lg w-100 btn-vk mt-3"
                onClick={() => handleSubmit()}
              >
                Entrar
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="loaderApp">
          <ReactLoading
            type="spinningBubbles"
            color="#ff5722"
            height={100}
            width={100}
          />
        </div>
      )}
    </>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  imagem: PropTypes.string,
};

export default withRouter(Login);
