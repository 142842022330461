import React, { Component } from "react";
import PropTypes from "prop-types";
import * as PrestAPI from "../../../service/api/PrestAPI";
import { toast } from "react-hot-toast";

class NewCategoriaComplement extends Component {
  static propTypes = {
    idEstabelecimento: PropTypes.number.isRequired,
    idProduto: PropTypes.number.isRequired,
    ocultaCategoria: PropTypes.bool.isRequired,
    getCatComplementos: PropTypes.func,
    ocultaCategoriaComponent: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    let status_categoria = formData.get("status_categoria") ? 1 : 0;
    let substitui_valor = formData.get("substitui_valor") ? true : false;

    let data = {
      nome_categoria: formData.get("nome_categoria"),
      qtde_min: parseInt(formData.get("qtde_min"), 10) || 0,
      qtde_maxima: parseInt(formData.get("qtde_maxima"), 10) || 1,
      descricao_categoria: formData.get("descricao_categoria") || "",
      status_categoria,
      substitui_valor,
      modo_multiplo: formData.get("qtde_maxima") > 1 ? 1 : 0,
      uso_obrigatorio: formData.get("qtde_min") >= 1 ? 1 : 0,
      id_estabelecimento: this.props.idEstabelecimento,
      id_produto: this.props.idProduto,
    };

    if (data.nome_categoria) {
      this.setState({ loading: true });
      this.saveCategoriaComplemento(data);
    } else {
      toast.error("Informe os dados necessários");
    }
  };

  saveCategoriaComplemento(complemento) {
    PrestAPI.add("ProdutoComplemento", complemento)
      .then((response) => {
        if (!response.error) {
          toast.success("Categoria salva com sucesso!");
          if (this.props.getCatComplementos) {
            this.props.getCatComplementos(response);
          }
          this.props.ocultaCategoriaComponent();
        } else {
          toast.error(response.error || "Erro ao salvar categoria.");
        }
      })
      .catch((error) => {
        toast.error("Erro ao salvar categoria.");
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  render() {
    const { ocultaCategoria } = this.props;
    const { loading } = this.state;

    if (ocultaCategoria) return null;

    return (
      <div className="container-fluid">
        <form onSubmit={this.handleSubmit} className="row">
          <div className="col-sm-4">
            <label className="form-label">Nome grupo</label>
            <input
              type="text"
              name="nome_categoria"
              className="form-control form-control-sm"
              required
            />
          </div>
          <div className="col-sm-3">
            <label className="form-label">Quantidade mínima</label>
            <input
              type="number"
              name="qtde_min"
              min="0"
              className="input-sm form-control form-control-sm"
              required
            />
          </div>
          <div className="col-sm-3">
            <label className="form-label">Quantidade máxima</label>
            <input
              type="number"
              name="qtde_maxima"
              className="form-control form-control-sm"
              required
            />
          </div>
          <div className="col-sm-2">
            <label htmlFor="status_categoria" className="form-check-label">
              Status:
            </label>
            <div className="form-check mt-1">
              <input
                type="checkbox"
                name="status_categoria"
                className="form-check-input"
                defaultChecked={true}
                style={{
                  width: "30px",
                  height: "30px",
                }}
              />
            </div>
          </div>
          <div className="col-sm-2">
            <label className="form-check-label">Substitui valor:</label>
            <div className="form-check mt-1">
              <input
                type="checkbox"
                name="substitui_valor"
                className="form-check-input"
                defaultChecked={false}
                style={{
                  width: "30px",
                  height: "30px",
                }}
              />
            </div>
          </div>
          <div className="col-sm-7">
            <label className="form-label">Descrição</label>
            <input
              type="text"
              name="descricao_categoria"
              className="form-control form-control-sm"
            />
          </div>
          <div className="col-sm-3 mt-2 align-content-end">
            <button
              className="btn btn-vk w-100"
              type="submit"
              disabled={loading}
            >
              {loading ? "Salvando..." : "Salvar"}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default NewCategoriaComplement;
