import { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import * as PrestAPI from "../../service/api/PrestAPI";

const useCategorias = () => {
  const [categorias, setCategorias] = useState([]);
  const [isLoadingCategorias, setIsLoadingCategorias] = useState(false);
  const [error, setError] = useState(null);

  const getCategorias = async () => {
    setIsLoadingCategorias(true);
    try {
      const result = await PrestAPI.get("Categorias");

      if (result && result.categorias) {
        setCategorias(result.categorias);
      } else {
        console.log("Nenhuma categoria encontrada.");
      }
    } catch (error) {
      console.error("Erro ao buscar categorias:", error);
      setError(error);
    } finally {
      setIsLoadingCategorias(false);
    }
  };

  useEffect(() => {
    getCategorias();
  }, []);

  return { categorias, isLoadingCategorias, error, getCategorias };
};

export default useCategorias;
