import axios from "axios";
import { getStorage } from "./getStorage";
import { Route, Redirect, history } from "react-router-dom";

const api = process.env.REACT_APP_API;
const apiGatepeeker = process.env.REACT_APP_API_GATEKEEPER;

export const apiClient = axios.create({
  baseURL: api,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
export const apiClientFormData = axios.create({
  baseURL: api,
  headers: {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
  },
});

apiClient.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

apiClientFormData.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const storage = getStorage();
    const filtroDados = {
      username: storage.uid,
      password: storage.ticket,
    };

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const tokenResponse = await getToken("panel", filtroDados);
        const newToken = tokenResponse.token || tokenResponse;

        if (newToken) {
          localStorage.setItem("token", newToken);
          apiClient.defaults.headers.common.Authorization = `Bearer ${newToken}`;
          originalRequest.headers.Authorization = `Bearer ${newToken}`;

          return apiClient(originalRequest);
          
        }
      } catch (tokenError) {
        console.error("Erro ao tentar renovar o token:", tokenError);
        window.location.href = "/login";
      }
    } else if (error.response && error.response.status === 401) {
      window.location.href = "/login";
    }

    return Promise.reject(error);
  }
);

export const getToken = async (table, data) => {
  try {
    const response = await axios.post(`${apiGatepeeker}/${table}`, data);
    if (response.data && response.data.value) {
      localStorage.setItem("token", response.data.token);
      return response.data;
    }
    return null;
  } catch (error) {
    console.error("Erro ao obter token:", error.response.data || error.message);
    throw new Error("Não foi possível obter o token.");
  }
};

export const get = async (table, where) => {
  const queryString = where ? `?${where}` : "";
  const response = await apiClient.get(`/${table}${queryString}`);
  return response.data;
};

export const update = async (table, where, content) => {
  const response = await apiClient.put(`/${table}${where}`, content);
  return response;
};

export const add = (table, value) => {
  return apiClient.post(`/${table}`, value);
};

export const del = async (table, where) => {
  const response = await apiClient.delete(`/${table}${where}`);
  return response;
};

export const delTaxa = async (table, value) => {
  const response = await apiClient.delete(`/${table}`, {
    data: value,
  });
  return response;
};

export const getCep = async (cep) => {
  const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
  return response.data;
};

export const getTokenAnonymous = async () => {
  const response = await axios.post(`${apiGatepeeker}/anonymous`);
  return response.data;
};

export const addParceiro = async (data) => {
  const tokenResponse = await getTokenAnonymous();
  const token = tokenResponse.token || tokenResponse;

  if (!token) {
    throw new Error("Token não foi obtido.");
  }
  const result = await apiClient.post("partner", data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return result;
};
