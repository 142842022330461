import React, { useContext } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import "./Header.css";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import {
  ExclamationTriangleFill,
  GearFill,
  BoxArrowRight,
} from "react-bootstrap-icons";
import CryptoJS from "crypto-js";
import EstabelecimentoContext from "../../context/EstabelecimentoContext";

const Header = ({ pedidosConfirmar, logout, getPedidos,estabelecimento }) => {
  return (
    <Navbar
      bg="light"
      expand="lg"
      className="navbar-fixed-top px-5 py-3 text-dark"
    >
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Brand href="/" className="text-dark fs-5 mx-auto">
        <b>{estabelecimento.razao_social || "Estabelecimento"}</b>
      </Navbar.Brand>

      <Navbar.Collapse id="basic-navbar-nav" className="mx-lg-auto">
        <Nav className="ms-lg-4 text-center">
          {estabelecimento ? (
            <>
              <Nav.Link
                as={Link}
                to="/produto"
                className="text-black navbar-btn"
              >
                Produtos
              </Nav.Link>

              <Nav.Link
                as={Link}
                to="/taxa_entrega-list"
                className="text-black navbar-btn"
              >
                Taxas
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/relatorios"
                className="text-black navbar-btn"
              >
                Relatórios
              </Nav.Link>
              <Nav.Link onClick={getPedidos} className="text-black navbar-btn">
                {pedidosConfirmar ? (
                  <div style={{ color: "orange" }}>
                    <ExclamationTriangleFill
                      size={16}
                      className="me-2"
                      aria-label="Pedidos pendentes"
                    />
                    <b>Pedidos</b>
                  </div>
                ) : (
                  "Pedidos"
                )}
              </Nav.Link>
            </>
          ) : null}
        </Nav>
        <Nav className="ms-lg-auto text-center">
          <Nav.Link as={Link} to="/Config" className="text-black navbar-btn">
            <GearFill aria-label="Configurações" />
            <span className="text-center">Config</span>
          </Nav.Link>

          {estabelecimento && (
            <Nav.Link onClick={logout} className="cursor-pointer navbar-btn">
              <BoxArrowRight aria-label="Sair" />
              <span>Sair</span>
            </Nav.Link>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
