import React, { useState, useContext, useEffect } from "react";
import "./Perfil.css";
import MobilePreview from "./MobilePreview";
import EstabelecimentoContext from "../../context/EstabelecimentoContext";
import toast from "react-hot-toast";
import InputMask from "react-input-mask";
import { formatPhone, formatTime } from "../../utils/formatters";
import { validateImage } from "../../utils/validators";
import ColorSelect from "./ColorSelect";
import useUploadImage from "../../hooks/imagens/useUploadImage";
import useUpdateEstabelecimento from "../../hooks/Establecimentos/useUpdateEstabelecimento";

const Perfil = () => {
  const { estabelecimento } = useContext(EstabelecimentoContext);
  const { updateEstabelecimento, isUpdating } = useUpdateEstabelecimento();
  const { uploadImage, isLoadingImage, imageUrl } = useUploadImage();

  const [selectedColors, setSelectedColors] = useState({
    a: "#FF5722",
    b: "#FE8862",
    c: "#FFBAA4",
    d: "#FFE6DE",
    e: "#FFE6DE",
  });
  const [imagePreview, setImagePreview] = useState(
    estabelecimento.imagem || null
  );
  const [imageFile, setImageFile] = useState(null);

  const handleColorSelect = (colors) => {
    setSelectedColors(colors);
  };

  const handleImageChange = (event) => {
    const { files } = event.target;
    const image = files[0];
    const validationError = validateImage(image);

    if (validationError) {
      toast.error(validationError);
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
      setImageFile(image);
    };
    reader.readAsDataURL(image);
  };

  const handleSubmit = async () => {
    let uploadedImageUrl = imagePreview;

    if (imageFile) {
      uploadedImageUrl = await uploadImage(imageFile);
      if (!uploadedImageUrl) {
        toast.error("Erro ao fazer upload da imagem.");
        return;
      }
    }

    const estabelecimentoToUpdate = {
      ...estabelecimento,
      imagem: uploadedImageUrl,
    };
    updateEstabelecimento(estabelecimentoToUpdate);
  };

  return (
    <div className="container-fluid">
      <div className="container page-config">
        <div className="row justify-content-center">
          <section className="col-md-3">
            <h3 className="text-center mb-4">Configurar minha loja</h3>
            <div className="circle-border overflow-hidden">
              {imagePreview ? (
                <img
                  src={imagePreview}
                  alt="Preview"
                  className="rounded-circle img-fluid"
                />
              ) : (
                <div className="input-img">
                  <label htmlFor="file">Upload Foto</label>
                  <input type="file" id="file" onChange={handleImageChange} />
                </div>
              )}
            </div>
            {imagePreview && (
              <button
                className="btn btn-outline-vk mt-2 w-100"
                onClick={() => setImagePreview(null)}
              >
                Mudar Imagem
              </button>
            )}
            <ColorSelect onColorSelect={handleColorSelect} />
            <button className="btn btn-vk mt-4 w-100">
              Visualizar cardápio ao vivo
            </button>

            <button
              className="btn btn-vk btn-lg w-100 mt-4"
              disabled={isUpdating || isLoadingImage}
              onClick={() => handleSubmit()}
            >
              {isUpdating ? "Salvando..." : "Salvar"}
            </button>
          </section>
          <section className="col-md-4">
            <h3 className="text-center">Visualizador</h3>
            <MobilePreview
              colors={selectedColors}
              imageUrl={imagePreview}
              nomeFantasia={estabelecimento.nome_fantasia}
            />
          </section>
        </div>
      </div>
    </div>
  );
};

export default Perfil;
