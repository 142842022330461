import React, { useState, useEffect } from "react";
import * as PrestAPI from "../../service/api/PrestAPI";
import toast, { Toaster } from "react-hot-toast";
import InputField from "../../components/InputField/InputField";
import InputMask from "react-input-mask";
import {
  formatCNPJ,
  formatIE,
  formatPhone,
  formatTime,
} from "../../utils/formatters";
import { validateImage, validateFields } from "../../utils/validators";
import HorariosAtendimento from "../../components/Horarios/Horarios";
import useCategorias from "../../hooks/Establecimentos/useCategoriasEstabelecimento";
import useUploadImage from "../../hooks/imagens/useUploadImage";

const tipoVendaPlataforma = [
  "anotejá subdominio",
  "anotejá multiloja subdominio",
  "anotejá dominio cliente",
  "anotejá multiloja dominio cliente",
];

const tipoExibicaoProduto = [
  "desativar carrinho e modal o botão adicionar",
  "exibir preço e habilitar carrinho somente cliente logado",
  "não exibir preço, e habilitar carrinho somente cliente logado",
];

const FormEstabelecimento = ({ estabelecimentoId, isEditing, onCancel }) => {
  const [estabelecimento, setEstabelecimento] = useState({
    razao_social: "",
    nome_fantasia: "",
    cnpj: "",
    ie: "",
    segmento: "",
    email: "",
    bairro: "",
    logradouro: "",
    numero: "",
    municipio: "",
    uf: "",
    tempo_minimo_entrega: 0,
    tipo_venda_plataforma: 0,
    permite_pagamento_online: 0,
    permite_uso_vempraka: 0,
    exibe_preco_apenas_cliente_logado: 0,
    habilita_cadastro_proprio_cliente: 0,
    desabilita_todos_tipo_venda: 0,
    habilita_marketplace_vempraka: 0,
    status: 0,
    imagem: null,
    complemento: "",
    horario: {
      horario_Domingo: "08:00|20:00",
      horario_Segunda: "08:00|20:00",
      horario_Terca: "08:00|20:00",
      horario_Quarta: "08:00|20:00",
      horario_Quinta: "08:00|20:00",
      horario_Sexta: "08:00|20:00",
      horario_Sabado: "08:00|20:00",
    },
    tipo_venda: {
      todos: 0,
      delivery: 0,
      retirada: 0,
      encomenda: 0,
      consumir: 1,
      cardapio: 0,
    },
  });

  const [imageUrl, setImageUrl] = useState(null);
  const [isLoadingEstabelecimento, setIsLoadingEstabelecimento] =
    useState(false);

  const { categorias, isLoadingCategorias } = useCategorias();
  const { uploadImage, isLoadingImage } = useUploadImage();

  useEffect(() => {
    if (estabelecimentoId) {
      getEstabelecimento(estabelecimentoId);
    }
  }, [estabelecimentoId]);

  const getEstabelecimento = async (id) => {
    setIsLoadingEstabelecimento(true);
    try {
      const res = await PrestAPI.get("Estabelecimento", `estab=${id}`);
      if (!res) throw new Error("Estabelecimento não encontrado");

      const mappedTipoVenda = {
        todos: 0,
        delivery: 0,
        retirada: 0,
        encomenda: res.permite_agendamento || 0,
        consumir: 0,
        cardapio: res.habilita_modo_cardapio || 0,
      };

      setEstabelecimento((prev) => ({
        ...res,
        tipo_venda: mappedTipoVenda,
      }));
    } catch (error) {
      toast.error(
        "Erro ao buscar o estabelecimento. Por favor, tente novamente."
      );
    } finally {
      setIsLoadingEstabelecimento(false);
    }
  };

  const addEstabelecimento = async (estabelecimentoData) => {
    try {
      const result = await PrestAPI.add("Estabelecimento", estabelecimentoData);
      result.status === 200
        ? toast.success("Estabelecimento Salvo com sucesso")
        : toast.error(result.error);
    } catch (error) {
      toast.error(error.message || "Erro ao adicionar o estabelecimento");
    }
  };


  const handleTimeChange = (event) => {
    const { name, value } = event.target;
    setEstabelecimento((prev) => ({
      ...prev,
      horario: { ...prev.horario, [name]: formatTime(value) },
    }));
  };

  const handleFileChange = (event) => {
    const { files } = event.target;
    const image = files[0];
    if (validateImage(image)) {
      setEstabelecimento((prev) => ({ ...prev, imagem: image }));
    }
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setEstabelecimento((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? (checked ? 1 : 0) : value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const errors = validateFields(estabelecimento);
    if (Object.keys(errors).length > 0) {
      Object.values(errors).forEach((error) => toast.error(error));
      return;
    }

    const preparedEstabelecimento = await prepareEstabelecimentoData();
    if (preparedEstabelecimento) {
      addEstabelecimento(preparedEstabelecimento);
    }
  };
  const prepareEstabelecimentoData = async () => {
    let updatedEstabelecimento = { ...estabelecimento };

    if (
      updatedEstabelecimento.imagem &&
      typeof updatedEstabelecimento.imagem !== "string"
    ) {
      const imageUrl = await uploadImage(updatedEstabelecimento.imagem);
      if (!imageUrl) return null;
      updatedEstabelecimento = { ...updatedEstabelecimento, imagem: imageUrl };
    }

    return {
      ...updatedEstabelecimento,
      contato_telefone: updatedEstabelecimento.telefone_contato,
      contato_email: updatedEstabelecimento.contato_email,
      senha: "123",
    };
  };

  if (isLoadingEstabelecimento || isLoadingCategorias) {
    return (
      <div className="loader" id="loader">
        <span></span>
        <span></span>
        <span></span>
      </div>
    );
  }

  return (
    <React.Fragment>
      <form className="form-horizontal row" onSubmit={handleSubmit}>
        <InputField
          className="col-md-6"
          label="Razão social"
          type="text"
          name="razao_social"
          placeholder="Digite a razão social"
          value={estabelecimento.razao_social}
          onChange={handleChange}
          required
        />
        <InputField
          className="col-md-6"
          label="Nome fantasia"
          type="text"
          name="nome_fantasia"
          placeholder="Digite o nome fantasia"
          value={estabelecimento.nome_fantasia}
          onChange={handleChange}
          required
        />
        <InputField
          className="col-md-4"
          label="CNPJ"
          type="text"
          name="cnpj"
          placeholder="Digite o CNPJ"
          value={formatCNPJ(estabelecimento.cnpj)}
          onChange={handleChange}
          required
        />
        <InputField
          className="col-md-4"
          label="Inscrição Estadual"
          type="text"
          name="ie"
          placeholder="Digite a inscrição estadual"
          value={formatIE(estabelecimento.ie)}
          onChange={handleChange}
        />
        <div className="col-md-4">
          <label htmlFor="segmento" className="form-label">
            Segmento
          </label>
          <select
            className="form-select"
            id="segmento"
            name="segmento"
            value={estabelecimento.segmento}
            onChange={handleChange}
            required
          >
            <option value="">Selecione o segmento</option>
            {categorias.map((categoria, index) => (
              <option key={index} value={categoria.nome}>
                {categoria.nome}
              </option>
            ))}
          </select>
        </div>
        <InputField
          className="col-md-4"
          label="Email"
          type="text"
          name="email"
          placeholder="Digite o email"
          value={estabelecimento.email}
          onChange={handleChange}
          required
        />
        <InputField
          className="col-md-4"
          label="Contato"
          type="text"
          name="contato"
          placeholder="Digite o nome do contato"
          value={estabelecimento.contato}
          onChange={handleChange}
        />
        <InputField
          className="col-md-4"
          label="Telefone do contato"
          type="text"
          name="telefone_contato"
          placeholder="(99) 99999-9999"
          value={formatPhone(estabelecimento.telefone_contato)}
          onChange={handleChange}
        />
        <InputField
          className="col-md-6"
          label="Bairro"
          type="text"
          name="bairro"
          placeholder="Digite o bairro"
          value={estabelecimento.bairro}
          onChange={handleChange}
        />
        <InputField
          className="col-md-6"
          label="Logradouro"
          type="text"
          name="logradouro"
          placeholder="Digite o logradouro"
          value={estabelecimento.logradouro}
          onChange={handleChange}
        />
        <InputField
          className="col-md-8"
          label="Complemento"
          type="text"
          name="complemento"
          placeholder="Digite o complemento"
          value={estabelecimento.complemento}
          onChange={handleChange}
        />
        <InputField
          className="col-md-4"
          label="CEP"
          type="text"
          name="cep"
          placeholder="Digite o CEP"
          value={estabelecimento.cep}
          onChange={handleChange}
        />
        <InputField
          className="col-md-2"
          label="Número"
          type="text"
          name="numero"
          placeholder="Digite o número"
          value={estabelecimento.numero}
          onChange={handleChange}
        />
        <InputField
          className="col-md-4"
          label="Cidade"
          type="text"
          name="municipio"
          placeholder="Digite a cidade"
          value={estabelecimento.municipio}
          onChange={handleChange}
        />
        <InputField
          className="col-md-2"
          label="UF"
          type="text"
          name="uf"
          placeholder="Digite a UF"
          value={estabelecimento.uf}
          onChange={handleChange}
        />
        <div className="col-md-4">
          <label htmlFor="imagem" className="form-label">
            Selecione uma imagem
          </label>
          <input
            className="form-control"
            name="imagem"
            type="file"
            onChange={handleFileChange}
          />
        </div>

        <div className="row border-bottom border-top py-2 my-2 mx-1">
          <div
            className="my-3 p-3 border border-2 rounded position-relative w-100"
            style={{ maxWidth: "300px" }}
          >
            <span
              className="position-absolute bg-white px-2"
              style={{ top: "-10px", left: "20px" }}
            >
              Tipos de vendas
            </span>
            <div className="row">
              {[
                "todos",
                "delivery",
                "retirada",
                "encomenda",
                "consumir",
                "cardapio",
              ].map((tipo, index) => (
                <div className="col-6" key={index}>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name={tipo}
                      id={`checkbox-${tipo}`}
                      checked={estabelecimento.tipo_venda[tipo] === 1}
                      onChange={handleChange}
                      aria-label={`Tipo de venda: ${tipo}`}
                    />
                    <label
                      htmlFor={`checkbox-${tipo}`}
                      className="form-check-label"
                    >
                      {tipo.charAt(0).toUpperCase() + tipo.slice(1)}
                    </label>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="col-lg-6">
            <label htmlFor="exibir_produtos" className="form-label">
              Tipos de Exibição dos produtos
            </label>
            <select
              className="form-select"
              name="exibir_produtos"
              value={estabelecimento.exibir_produtos}
              onChange={handleChange}
            >
              {tipoExibicaoProduto.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row border-bottom py-2 my-2">
          <div className="col-lg-5">
            <label htmlFor="tipo_venda_plataforma" className="form-label">
              Tipos de venda plataforma Anotejá
            </label>
            <select
              className="form-select"
              name="tipo_venda_plataforma"
              value={estabelecimento.tipo_venda_plataforma}
              onChange={handleChange}
            >
              {tipoVendaPlataforma.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <div className="col-lg-6">
            <label htmlFor="url" className="form-label">
              Url do dominio
            </label>
            <input
              type="text"
              name="url"
              placeholder="Digite a url"
              className="form-control"
            />
          </div>
        </div>
        <div className="w-100 py-2 border-bottom border-light">
          <div
            className="my-3 p-3 border border-2 rounded position-relative w-100"
            style={{ maxWidth: "300px" }}
          >
            <span
              className="position-absolute bg-white px-1"
              style={{ top: "-10px", left: "20px" }}
            >
              Tipos de venda plataforma Vempraka
            </span>
            <div className="row">
              <div className="col-6">
                <div className="form-check align-items-center">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="vempraka"
                  />
                  <label htmlFor="vempraka" className="form-check-label">
                    Vempraka
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-100 py-2 border-bottom border-light">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target="#home"
                type="button"
                role="tab"
                aria-controls="home"
                aria-selected="true"
              >
                <h5 className="text-center fs-5">Horário de atendimento</h5>
              </button>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="home"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <HorariosAtendimento horarios={estabelecimento.horarios} />
            </div>
          </div>
        </div>
        {estabelecimento.tipo_venda.encomenda === 1 && (
          <InputField
            className="col-md-2"
            label="Tempo mínimo para entrega"
            type="text"
            name="tempo_minimo_entrega"
            placeholder="Digite o tempo mínimo para entrega"
            value={estabelecimento.tempo_minimo_entrega}
            onChange={handleChange}
          />
        )}

        <div className="col-md-4">
          <label className="form-label">Empresa Ativa:</label>
          <div className="row input-group ">
            <label htmlFor="empresa-ativa-sim" className="radio-label">
              <input
                type="radio"
                name="status"
                id="empresa-ativa-sim"
                value={1}
                aria-label="Ativar empresa"
                checked={estabelecimento.status === 1}
                onChange={() =>
                  setEstabelecimento((prevState) => ({
                    ...prevState,
                    status: 1,
                  }))
                }
              />
              Sim
            </label>
            <label htmlFor="empresa-ativa-nao" className="radio-label">
              <input
                type="radio"
                name="status"
                value={0}
                id="empresa-ativa-nao"
                aria-label="Desativar empresa"
                checked={estabelecimento.status === 0}
                onChange={() =>
                  setEstabelecimento((prevState) => ({
                    ...prevState,
                    status: 0,
                  }))
                }
              />
              Não
            </label>
          </div>
        </div>

        <div className="row my-2">
          <div className="col-lg-3">
            <button
              type="submit"
              className="btn btn-vk w-100"
              aria-label={isEditing ? "Salvar Alterações" : "Cadastrar Empresa"}
            >
              {isEditing ? "Salvar Alterações" : "Cadastrar Empresa"}
            </button>
          </div>
          <div className="col-lg-2">
            <button
              type="button"
              className="btn btn-outline-secondary w-100"
              onClick={onCancel}
              aria-label="Cancelar e retornar"
            >
              Cancelar
            </button>
          </div>
        </div>
      </form>
      <Toaster />
    </React.Fragment>
  );
};

export default FormEstabelecimento;
