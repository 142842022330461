import { useState } from "react";
import { toast } from "react-hot-toast";
import { apiClient } from "../../service/api/PrestAPI";

const useUpdateEstabelecimento = () => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [error, setError] = useState(null);

  const updateEstabelecimento = async (estabelecimento) => {
    setIsUpdating(true);
    setError(null);

    try {
      const result = await apiClient.put(
        `Estabelecimento?idEstab=${estabelecimento.id_estabelecimento}`,
        estabelecimento
      );

      if (result.status === 200 || result === 204) {
        toast.success("Estabelecimento atualizado com sucesso!");
        return result.data;
      } else {
        throw new Error(
          result.data.message || "Erro ao atualizar o estabelecimento."
        );
      }
    } catch (err) {
      setError(err.message || "Erro desconhecido.");
      toast.error(err.message || "Erro ao atualizar o estabelecimento.");
      return null;
    } finally {
      setIsUpdating(false);
    }
  };

  return { updateEstabelecimento, isUpdating, error };
};

export default useUpdateEstabelecimento;
