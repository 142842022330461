import React, { useState } from "react";
import Slider from "react-slick";
import { toast } from "react-hot-toast";
import * as PrestAPI from "../../service/api/PrestAPI";
import Anoteja from "../../assets/imagens/anoteja.png";
import Monitor from "../../assets/imagens/monitor.png";
import Vempraka from "../../assets/imagens/vempraka.svg";
import AnotejaLogo from "../../assets/imagens/anotejalogo.png";
import { validateCNPJ } from "../../utils/validateCnpj";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useForm } from "react-hook-form";
import { z } from "zod";
import InputMask from "react-input-mask";
import { zodResolver } from "@hookform/resolvers/zod";
import useCategorias from "../../hooks/Establecimentos/useCategoriasEstabelecimento";
import { useAuthEstabelecimento } from "../../hooks/auth/useAuthEstabelecimento";

const parceiroSchema = z
  .object({
    razao_social: z.string().nonempty("Nome da loja é obrigatório"),
    telefone: z.string().nonempty("Telefone é obrigatório"),
    cnpj: z
      .string()
      .nonempty("CNPJ é obrigatório")
      .refine((value) => validateCNPJ(value), { message: "CNPJ inválido" }),
    municipio: z.string().nonempty("Cidade é obrigatória"),
    email: z.string().nonempty("Email é obrigatório").email("Email inválido"),
    senha: z.string().nonempty("Senha é obrigatória"),
    confirmarSenha: z.string().nonempty("Confirmação de senha é obrigatória"),
    segmento: z.string().nonempty("Segmento é obrigatório"),
    cep: z
      .string()
      .nonempty("CEP é obrigatório")
      .regex(/^[0-9]{5}-[0-9]{3}$/, { message: "CEP inválido. Ex: 12345-678" }),
  })
  .refine((data) => data.senha === data.confirmarSenha, {
    message: "As senhas devem corresponder",
    path: ["confirmarSenha"],
  });

const Parceiro = ({ name }) => {
  const { categorias } = useCategorias();
  const [plano, setPlano] = useState(name);
  const [loading, setLoading] = useState(false);
  const { login } = useAuthEstabelecimento();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: zodResolver(parceiroSchema),
  });

  const addParceiro = async (estabelecimento) => {
    setLoading(true);
    try {
      const result = await PrestAPI.addParceiro(estabelecimento);

      if (result.status === 200) {
        toast.success(
          "Cadastro efetuado com sucesso, \n\n  você está sendo direcionado para a página de login, \n\n entre com seu e-mail e senha cadastrado! \n\n Enviamos um e-mail de confirmação e boas-vindas!",
          {
            style: { fontWeight: "bold" },
            id: "parceiroCadastro",
            duration: 4000,
          }
        );

        setTimeout(() => {
          window.location.href = "http://painel.vempraka.com.br/login";
        }, 4000);
      } else {
        const errorMessage =
          result.data.error || "Erro ao criar o cadastro na nossa plataforma";
        toast.error(errorMessage);
      }
    } catch (error) {
      const errorMessage =
        error.response.data.error ||
        error.message ||
        "Erro ao criar o cadastro na nossa plataforma";
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const onSubmit = (values) => {
    const estabelecimentoData = {
      ...values,
      permite_agendamento: 0,
      tempo_minimo_agendamento: 0,
      tipo_venda_plataforma: 0,
      permite_uso_vempraka: 1,
      permite_pagamento_online: 0,
      exibe_preco_apenas_cliente_logado: 0,
      habilita_cadastro_proprio_cliente: 0,
      desabilita_todos_tipo_venda: 0,
      habilita_marketplace_vempraka: 0,
      status: 1,
      latitude: 0,
      longitude: 0,
    };

    addParceiro(estabelecimentoData);
  };

  return (
    <div
      className="container-fluid m-0 pb-5"
      style={{
        backgroundColor: plano === "anoteja" ? "#FDB10B" : "#FFFFFF",
      }}
    >
      <div className="row pb-4">
        <header>
          <img
            alt="Logo"
            src={plano === "anoteja" ? AnotejaLogo : Vempraka}
            className="logo"
          />
        </header>
        <section className="col-md-8" id="slide">
          <h1 className="text-center" style={{ color: "#fb0c01" }}>
            Seja nosso parceiro
          </h1>
          <div>
            <Slider {...settings}>
              <div>
                <img
                  src={Anoteja}
                  alt="Slide 1"
                  className="img-responsive m-auto"
                />
              </div>
              <div>
                <img
                  src={Monitor}
                  alt="Slide 2"
                  className="img-responsive m-auto"
                />
              </div>
              <div>
                <img
                  src={Anoteja}
                  alt="Slide 3"
                  className="img-responsive m-auto"
                />
              </div>
            </Slider>
          </div>
        </section>
        <section className="col-md-4">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="form-horizontal p-3"
          >
            <h3
              className="text-center"
              style={{
                color: plano === "anoteja" ? "#FFFFFF" : "#000000",
              }}
            >
              Cadastro
            </h3>

            <div className="col mb-3">
              <input
                type="text"
                className="form-control form-control-lg rounded-4 bg-light"
                placeholder="Nome da loja"
                {...register("razao_social")}
              />
              {errors.razao_social && (
                <div className="text-danger">{errors.razao_social.message}</div>
              )}
            </div>

            <div className="col mb-3">
              <InputMask
                mask="(99) 99999-9999"
                className="form-control form-control-lg rounded-4 bg-light"
                placeholder="Tel. WhatsApp"
                {...register("telefone")}
                onChange={(e) => setValue("telefone", e.target.value)}
              />
              {errors.telefone && (
                <div className="text-danger">{errors.telefone.message}</div>
              )}
            </div>

            <div className="col mb-3">
              <InputMask
                mask="99.999.999/9999-99"
                className="form-control form-control-lg rounded-4 bg-light"
                placeholder="CNPJ"
                {...register("cnpj")}
                onChange={(e) => setValue("cnpj", e.target.value)}
              />
              {errors.cnpj && (
                <div className="text-danger">{errors.cnpj.message}</div>
              )}
            </div>

            <div className="col mb-3">
              <input
                type="text"
                className="form-control form-control-lg rounded-4 bg-light"
                placeholder="Cidade"
                {...register("municipio")}
              />
              {errors.municipio && (
                <div className="text-danger">{errors.municipio.message}</div>
              )}
            </div>

            <div className="col mb-3">
              <InputMask
                mask="99999-999"
                type="text"
                className="form-control form-control-lg rounded-4 bg-light"
                placeholder="CEP"
                {...register("cep")}
                onChange={(e) => setValue("cep", e.target.value)}
              />
              {errors.cep && (
                <div className="text-danger">{errors.cep.message}</div>
              )}
            </div>

            <div className="col mb-3">
              <select
                className="form-select form-select-lg rounded-4 bg-light"
                {...register("segmento")}
              >
                <option value="">Selecione o segmento</option>
                {categorias &&
                  categorias.map((categoria, index) => (
                    <option key={index} value={categoria.nome}>
                      {categoria.nome}
                    </option>
                  ))}
              </select>
              {errors.segmento && (
                <div className="text-danger">{errors.segmento.message}</div>
              )}
            </div>

            <h3
              className="text-center"
              style={{
                color: plano === "anoteja" ? "#FFFFFF" : "#000000",
              }}
            >
              Dados de acesso
            </h3>

            <div className="col mb-3">
              <input
                type="email"
                className="form-control form-control-lg rounded-4 bg-light"
                placeholder="E-mail da loja"
                {...register("email")}
              />
              {errors.email && (
                <div className="text-danger">{errors.email.message}</div>
              )}
            </div>

            <div className="row">
              <div className="col-xl-6">
                <input
                  type="password"
                  className="form-control form-control-lg rounded-4 bg-light"
                  placeholder="Senha"
                  {...register("senha")}
                />
                {errors.senha && (
                  <div className="text-danger">{errors.senha.message}</div>
                )}
              </div>

              <div className="col-xl-6">
                <input
                  type="password"
                  className="form-control form-control-lg rounded-4 bg-light"
                  placeholder="Confirmar senha"
                  {...register("confirmarSenha")}
                />
                {errors.confirmarSenha && (
                  <div className="text-danger">
                    {errors.confirmarSenha.message}
                  </div>
                )}
              </div>
            </div>

            <button
              type="submit"
              className="btn btn-vk btn-lg w-100 mt-4"
              disabled={loading}
            >
              {loading ? "Carregando..." : "Começar a vender"}
            </button>
          </form>
        </section>
      </div>
    </div>
  );
};

export default Parceiro;
