import { useState } from "react";
import { apiClientFormData } from "../../service/api/PrestAPI";

const useUploadImage = () => {
  const [imageUrl, setImageUrl] = useState(null);
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [error, setError] = useState(null);

  const uploadImage = async (image) => {
    if (!image) {
      setError("Nenhuma imagem selecionada");
      return;
    }

    setIsLoadingImage(true);
    setError(null);

    try {
      const formData = new FormData();
      formData.append("file", image);

      const result = await apiClientFormData.post("upload-image", formData);

      if (result.status === 200) {
        const data = result.data;
        setImageUrl(data.imageUrl);
        return data.imageUrl;
      } else {
        setError(result.data.error || "Erro ao carregar imagem");
      }
    } catch (error) {
      setError(error.message || "Erro ao adicionar imagem");
    } finally {
      setIsLoadingImage(false);
    }

    return null;
  };

  return {
    imageUrl,
    isLoadingImage,
    error,
    uploadImage,
  };
};

export default useUploadImage;
