import React, { useState, useEffect } from "react";
import * as PrestAPI from "../../service/api/PrestAPI";
import toast, { Toaster } from "react-hot-toast";
import FormEstabelecimento from "./FormEstabelecimento";
import { Pencil, Trash3 } from "react-bootstrap-icons";

const Empresas = () => {
  const [estabelecimentos, setEstabelecimentos] = useState([]);
  const [showCampos, setShowCampos] = useState(false);
  const [selectedEstabelecimentoId, setSelectedEstabelecimentoId] =
    useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getAllEstabelecimentos();
  }, []);

  const getAllEstabelecimentos = async () => {
    setIsLoading(true);
    try {
      const res = await PrestAPI.get("Estabelecimento");
      setEstabelecimentos(res.estabelecimentos);
    } catch (error) {
      toast.error(error.message || "Erro ao buscar os estabelecimentos");
    } finally {
      setIsLoading(false);
    }
  };

  const toggleForm = (isEditing = false, estabelecimentoId = null) => {
    setShowCampos(true);
    setIsEditing(isEditing);
    setSelectedEstabelecimentoId(estabelecimentoId);
  };

  const handleCancel = () => {
    setShowCampos(false);
  };

  const handleDelete = async (id) => {};

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredEstabelecimentos = estabelecimentos.filter((estabelecimento) =>
    estabelecimento.razao_social
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  return (
    <section className="container-fluid">
      <div className="w-100 py-4">
        {!showCampos && (
          <button
            type="button"
            className="btn btn-vk"
            onClick={() => toggleForm(false)}
          >
            Cadastrar Nova Empresa
          </button>
        )}
        {showCampos && (
          <FormEstabelecimento
            estabelecimentoId={selectedEstabelecimentoId}
            isEditing={isEditing}
            onCancel={handleCancel}
          />
        )}
        <div className="row my-2 mx-0 px-0">
          <div className="col-lg-6 px-0">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                name="search"
                placeholder="Pesquisar"
                value={searchQuery}
                onChange={handleSearchChange}
              />
              <button className="btn btn-vk">Pesquisar</button>
            </div>
          </div>
        </div>
        <div className="table-responsive mt-4">
          <table className="table table-bordered table-striped">
            <thead>
              <tr>
                <th>Nome</th>
                <th>Email</th>
                <th>Telefone</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan="4">
                    <div className="loader" id="loader">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </td>
                </tr>
              ) : (
                filteredEstabelecimentos.map((estabelecimento) => (
                  <tr key={estabelecimento.id_estabelecimento}>
                    <td>{estabelecimento.razao_social}</td>
                    <td>{estabelecimento.email}</td>
                    <td>{estabelecimento.telefone}</td>
                    <td className="d-flex justify-content-evenly">
                      <button
                        className="btn btn-outline-light"
                        title="Editar"
                        onClick={() =>
                          toggleForm(true, estabelecimento.id_estabelecimento)
                        }
                      >
                        <Pencil color="#FF5722" />
                      </button>

                      <button
                        className="btn btn-outline-light"
                        title="Excluir"
                        onClick={() => handleDelete(estabelecimento.id)}
                      >
                        <Trash3 color="#FF5722" />
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
      <Toaster />
    </section>
  );
};

export default Empresas;
