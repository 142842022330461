import React, { Component } from "react";
import PropTypes from "prop-types";
import * as PrestAPI from "../../../service/api/PrestAPI";
import { toast } from "react-hot-toast";
import "./styles.css";

class ComponentItem extends Component {
  static propTypes = {
    modo: PropTypes.string.isRequired,
    item: PropTypes.shape({
      id_categoria_complemento: PropTypes.string,
      id_complemento: PropTypes.string,
      nome_complemento: PropTypes.string,
      descricao_complemento: PropTypes.string,
      vr_adicional: PropTypes.number,
      cod_externo: PropTypes.string,
      sequencia: PropTypes.string,
      status_complemento: PropTypes.number,
    }),

    ocultar: PropTypes.bool.isRequired,
    onClosed: PropTypes.func.isRequired,
    getCatComplementos: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
    complemento: {
      nome_complemento: "",
      descricao_complemento: "",
      vr_adicional: 0.0,
      cod_externo: "",
      sequencia: "",
      status_complemento: 1,
      id_categoria_complemento: "",
      id_complemento: "",
    },
  };

  componentDidMount() {
    const { item, modo } = this.props;

    if (modo === "editar") {
      this.setState({ complemento: item });
    } else {
      this.setState({
        complemento: {
          ...this.state.complemento,
          id_categoria_complemento: item.id_categoria_complemento,
          id_complemento: item.id_complemento,
        },
      });
    }
  }

  handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    this.setState((prevState) => ({
      complemento: {
        ...prevState.complemento,
        [name]: type === "checkbox" ? checked : value,
      },
    }));
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { modo } = this.props;
    const { complemento } = this.state;
    const btnClick = event.nativeEvent.submitter.name;

    this.setState({ loading: true });

    try {
      if (modo === "novo") {
        await this.saveComplementItem(complemento);
      }
      if (modo === "editar") {
        await this.editComplementItem(complemento);
      }

      this.props.getCatComplementos();
    } catch (error) {
      const errorMsg =
        error.response.data.message || "Erro ao processar a solicitação.";
      toast.error(errorMsg);
    } finally {
      this.setState({ loading: false });
    }
  };

  saveComplementItem = async (complemento) => {
    const item = {
      ...complemento,
      status_complemento: complemento.status_complemento ? 1 : 0,
      id_categoria_complemento: Number.parseInt(
        complemento.id_categoria_complemento,
        10
      ),
      id_complemento: Number.parseInt(complemento.id_complemento, 10),
      vr_adicional: parseFloat(complemento.vr_adicional),
      sequencia: Number.parseInt(complemento.sequencia, 10),
    };

    try {
      const response = await PrestAPI.add("ProdutoComplementoItem", item);
      if (response.status === 200) {
        toast.success("Complemento adicionado com sucesso!");
        this.props.onClosed();
      }
    } catch (error) {
      const errorMsg =
        error.response.data.message || "Erro ao adicionar complemento.";
      toast.error(errorMsg);
    }
  };

  editComplementItem = async (complemento) => {
    const item = {
      ...complemento,
      status_complemento: complemento.status_complemento ? 1 : 0,
      id_categoria_complemento: Number.parseInt(
        complemento.id_categoria_complemento,
        10
      ),
      id_complemento: Number.parseInt(complemento.id_complemento, 10),
      vr_adicional: parseFloat(complemento.vr_adicional),
      sequencia: Number.parseInt(complemento.sequencia, 10),
    };

    try {
      const response = await PrestAPI.update(
        "ProdutoComplementoItem",
        `?idComplemento=${item.id_complemento}`,
        item
      );
      if (response.status === 200) {
        toast.success("Complemento salvo com sucesso!");
        this.props.onClosed();
      }
    } catch (error) {
      const errorMsg =
        error.response.data.message || "Erro ao salvar complemento.";
      toast.error(errorMsg);
    }
  };

  handleExcluir = async (complemento) => {
    if (this.props.modo !== "editar") {
      return;
    }
    try {
      const response = await PrestAPI.del(
        "ProdutoComplementoItem",
        `?idCatComplemento=${complemento.id_categoria_complemento}&idComplemento=${complemento.id_complemento}`
      );
      if (response.status === 200) {
        toast.success("Complemento excluído com sucesso!");
        this.props.onClosed();
      }
    } catch (error) {
      const errorMsg =
        error.response.data.message || "Erro ao excluir complemento.";
      toast.error(errorMsg);
    }
  };

  render() {
    const { ocultar, modo } = this.props;
    const { complemento } = this.state;

    return (
      <div>
        {ocultar && (
          <div className="container-fluid">
            <form onSubmit={this.handleSubmit} className="row g-3 p-2">
              <div className="col-12 fs-4">
                <h5>Complemento:</h5>
              </div>
              <div className="row">
                <div className="col-sm-6 mt-2">
                  <label className="form-label">Nome complemento:</label>
                  <input
                    type="text"
                    name="nome_complemento"
                    value={complemento.nome_complemento}
                    onChange={this.handleInputChange}
                    className="form-control input-sm"
                  />
                </div>
                <div className="col-sm-3 mt-2">
                  <label className="form-label">Cod Externo:</label>
                  <input
                    type="text"
                    name="cod_externo"
                    value={complemento.cod_externo}
                    onChange={this.handleInputChange}
                    className="form-control input-sm"
                  />
                </div>
                <div className="col-sm-2 mt-2">
                  <label className="form-label">Sequência:</label>
                  <input
                    type="number"
                    name="sequencia"
                    value={complemento.sequencia}
                    onChange={this.handleInputChange}
                    className="form-control input-sm"
                  />
                </div>
                <div className="col-sm-1 mt-2">
                  <label className="form-label form-check-label">Status:</label>
                  <input
                    type="checkbox"
                    name="status_complemento"
                    checked={complemento.status_complemento}
                    onChange={this.handleInputChange}
                    className="form-check-input form-control"
                    style={{ width: "30px", height: "30px" }}
                  />
                </div>
                <div className="col-sm-8 mt-2">
                  <label className="form-label">Descrição:</label>
                  <input
                    type="text"
                    name="descricao_complemento"
                    value={complemento.descricao_complemento}
                    onChange={this.handleInputChange}
                    className="form-control input-sm"
                  />
                </div>
                <div className="col-sm-2 mt-2">
                  <label className="form-label">Preço:</label>
                  <input
                    type="text"
                    name="vr_adicional"
                    value={complemento.vr_adicional}
                    onChange={this.handleInputChange}
                    className="form-control input-sm"
                  />
                </div>
                <div className="col-sm-3 mb-0 mt-3">
                  <button
                    className="form-control input-sm btn btn-vk my-auto"
                    type="submit"
                  >
                    Salvar
                  </button>
                </div>
                <div className="col-sm-3 mb-0 mt-3">
                  {modo === "editar" && (
                    <button
                      className="form-control input-sm btn btn-outline-vk my-auto"
                      type="button"
                      onClick={() => this.handleExcluir(complemento)}
                    >
                      Excluir
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
    );
  }
}

export default ComponentItem;
