import React, { Component } from "react";
import PropTypes from "prop-types";
import * as PrestAPI from "../../../service/api/PrestAPI";
import { toast } from "react-hot-toast";
import "./styles.css";

class ModalCategoriaComplement extends Component {
  static propTypes = {
    categoria: PropTypes.object.isRequired,
    getCatComplementos: PropTypes.func.isRequired,
    hideModal: PropTypes.func.isRequired,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { categoria } = this.props;
    const formData = new FormData(e.target);

    const data = {
      nome_categoria: formData.get("nome_categoria"),
      qtde_min: parseInt(formData.get("qtde_min"), 10) || 0,
      qtde_maxima: parseInt(formData.get("qtde_maxima"), 10) || 1,
      sequencia: parseInt(formData.get("sequencia"), 10) || 0,
      status_categoria: formData.get("status_categoria") ? 1 : 0,
      substitui_valor: formData.get("substitui_valor") ? true : false,
      descricao_categoria: formData.get("descricao_categoria") || "",
      modo_multiplo: formData.get("qtde_maxima") > 1 ? 1 : 0,
      uso_obrigatorio: formData.get("qtde_min") >= 1 ? 1 : 0,
      id_estabelecimento: parseInt(categoria.id_estabelecimento, 10),
      id_produto: parseInt(categoria.id_produto, 10),
      id_categoria_complemento: parseInt(
        categoria.id_categoria_complemento,
        10
      ),
    };

    this.atualizaCat(data);
  };

  atualizaCat = (categoria) => {
    PrestAPI.update(
      "ProdutoComplemento",
      `?idCategoria=${categoria.id_categoria_complemento}`,
      categoria
    )
      .then((res) => {
        if (res.status === 200) {
          toast.success("Categoria atualizada com sucesso!");
          this.props.getCatComplementos();
          this.props.hideModal();
        } else {
          toast.error("Erro ao atualizar categoria.");
        }
      })
      .catch(() => {
        toast.error("Erro ao atualizar categoria.");
      });
  };

  deleteCatComplemento = (idEstab, idProd, idCat) => {
    PrestAPI.del(
      "ProdutoComplemento",
      `?estab=${idEstab}&idProduto=${idProd}&idCatComplemento=${idCat}`
    )
      .then(() => {
        toast.success("Categoria excluída com sucesso!");
        this.props.hideModal();
        this.props.getCatComplementos();
      })
      .catch(() => {
        toast.error("Erro ao excluir categoria.");
      });
  };

  handleDelete = () => {
    const { categoria } = this.props;

    this.deleteCatComplemento(
      categoria.id_estabelecimento,
      categoria.id_produto,
      categoria.id_categoria_complemento
    );
  };

  render() {
    const { categoria } = this.props;

    return (
      <div className="container-fluid p-2">
        <form onSubmit={this.handleSubmit} className="row g-3">
          <div className="row fs-4">
            <b>Editar grupo</b>
          </div>
          <div className="col-sm-5">
            <label className="form-label">Nome grupo</label>
            <input
              type="text"
              name="nome_categoria"
              defaultValue={categoria.nome_categoria}
              className="form-control form-control-sm"
              required
            />
          </div>
          <div className="col-sm-2">
            <label className="form-label">Qtde Min</label>
            <input
              type="number"
              name="qtde_min"
              defaultValue={categoria.qtde_min}
              min="0"
              className="form-control form-control-sm text-center"
              required
            />
          </div>
          <div className="col-sm-2">
            <label className="form-label">Qtde Max</label>
            <input
              type="number"
              name="qtde_maxima"
              defaultValue={categoria.qtde_maxima}
              className="form-control form-control-sm text-center"
              required
            />
          </div>
          <div className="col-sm-2">
            <label className="form-label">Sequência</label>
            <input
              type="number"
              name="sequencia"
              defaultValue={categoria.sequencia}
              className="form-control form-control-sm text-center"
              required
            />
          </div>
          <div className="col-sm-1">
            <label
              className="form-check-label form-label"
              htmlFor="status_categoria"
            >
              Status:
            </label>
            <div className="form-check">
              <input
                type="checkbox"
                name="status_categoria"
                className="form-check-input"
                defaultChecked={categoria.status_categoria === 1}
                id="status_categoria"
                style={{ width: "30px", height: "30px" }}
              />
            </div>
          </div>
          <div className="col-sm-2">
            <label
              className="form-check-label form-label"
              htmlFor="substitui_valor"
            >
              Substitui valor:
            </label>
            <div className="form-check">
              <input
                type="checkbox"
                name="substitui_valor"
                defaultChecked={categoria.substitui_valor}
                className="form-check-input"
                id="substitui_valor"
                style={{ width: "30px", height: "30px" }}
              />
            </div>
          </div>
          <div className="col-sm-5">
            <label className="form-label">Descrição</label>
            <input
              type="text"
              name="descricao_categoria"
              defaultValue={categoria.descricao_categoria}
              className="form-control form-control-sm"
            />
          </div>
          <div className="col-sm-2 align-content-end">
            <button type="submit" className="input-sm btn btn-vk w-100">
              Salvar
            </button>
          </div>
          <div className="col-sm-2 align-content-end">
            <button
              type="button"
              onClick={this.handleDelete}
              className="input-sm btn btn-outline-vk w-100"
            >
              Excluir
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default ModalCategoriaComplement;
