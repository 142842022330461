import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import ImageInput from "../../components/Imagens/ImageInput";
import * as PrestAPI from "../../service/api/PrestAPI";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import "./Style.css";
import CatComponent from "./Components/CatComponente";
import AgendamentoProduto from "./AgendamentoProduto";
import { toast } from "react-hot-toast";

class ProdutoForm extends Component {
  static propTypes = {
    produto: PropTypes.object.isRequired,
    saveProduto: PropTypes.func.isRequired,
    isProdutoNew: PropTypes.bool.isRequired,
    redirectAdd: PropTypes.func,
    selectedCategoria: PropTypes.string,
  };

  state = {
    complementos: [],
    grupos: [],
    grupo: {},
    id_grupo: 0,
    tabIndex: 0,
    modo: "",
    inicio_oferta: "",
    fim_oferta: "",
    valor_oferta: 0,
    selectedComp: {},
    isCompSelected: false,
    imageUrl: null,
    imagem: null,
    status_produto:
      this.props.produto.status_produto !== undefined
        ? this.props.produto.status_produto
        : 1,
    id_produto: this.props.produto.id_produto || "",
    codigo_controle: this.props.produto.codigo_controle || "",
    nome: this.props.produto.nome || "",
    vr_unitario: this.props.produto.vr_unitario || "",
    tag: this.props.produto.tag || "",
    descricao: this.props.produto.descricao || "",
    errors: {},
  };

  componentDidMount() {
    const produto = this.props.produto;

    PrestAPI.get(
      "ProdutoGrupo",
      `estab=${produto.id_estabelecimento}&modo=retaguarda`
    ).then((value) => {
      this.setState({ grupos: value.categorias });

      if (produto) {
        this.defineGrupo(value.categorias);
      }
    });

    this.setState({ id_grupo: produto.id_grupo });
    this.setState({ complementos: produto.complementos });
    this.getCategoriaComplemento(
      produto.id_produto,
      produto.id_estabelecimento
    );

    if (
      produto.inicio_oferta &&
      !isNaN(new Date(produto.inicio_oferta).getTime()) &&
      produto.fim_oferta &&
      !isNaN(new Date(produto.fim_oferta).getTime())
    ) {
      let inicio_oferta = new Date(produto.inicio_oferta)
        .toISOString()
        .substring(0, 10);
      let fim_oferta = new Date(produto.fim_oferta)
        .toISOString()
        .substring(0, 10);
      let valor_oferta = produto.valor_oferta;

      this.setState({ inicio_oferta, fim_oferta, valor_oferta });
    } else {
      console.log("Data inválida fornecida para início ou fim de oferta");
    }
  }

  defineGrupo = (grupos) => {
    let produto = this.props.produto;
    let grupo = grupos.find((gp) => gp.id_grupo === produto.id_grupo);
    if (grupo) {
      this.setState({ grupo });
    }
  };

  getCategoriaComplemento(idProduto, idEstabelecimento) {
    PrestAPI.get(
      "ProdutoComplemento",
      `estab=${idEstabelecimento}&idProduto=${idProduto}&modo=retaguarda`
    ).then((value) => {
      this.setState({ complementos: value.complementos });
    });
  }

  uploadImage = async (image) => {
    try {
      const formData = new FormData();
      formData.append("file", image);

      const result = await PrestAPI.apiClientFormData.post(
        "upload-image",
        formData
      );

      if (result) {
        return result.data.imageUrl;
      } else {
        const errorResponse = await result.json();
        console.log(errorResponse.error || "Erro ao carregar imagem");
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    this.setState({
      [name]: type === "checkbox" ? checked : value,
    });
  };

  handleImageChange = (url) => {
    this.setState({ imagem: url });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    let modo = this.state.modo;

    const {
      status_produto,
      id_grupo,
      nome,
      vr_unitario,
      codigo_controle,
      descricao,
      tag,
      inicio_oferta,
      fim_oferta,
      valor_oferta,
    } = this.state;

    let errors = {};

    if (!nome) {
      errors.nome = "O nome do produto é obrigatório.";
    }

    if (!vr_unitario || isNaN(parseFloat(vr_unitario))) {
      errors.vr_unitario =
        "O valor unitário é obrigatório e deve ser um número.";
    }

    if (!id_grupo || id_grupo === 0) {
      errors.id_grupo = "Você deve selecionar um grupo.";
    }

    if (modo === "oferta") {
      if (!inicio_oferta || isNaN(new Date(inicio_oferta).getTime())) {
        errors.oferta_data = "A data de início da oferta é inválida.";
      }
      if (!fim_oferta || isNaN(new Date(fim_oferta).getTime())) {
        errors.oferta_data = "A data de fim da oferta é inválida.";
      } else if (new Date(inicio_oferta) > new Date(fim_oferta)) {
        errors.oferta_data =
          "A data de início deve ser anterior à data de fim.";
      }

      if (valor_oferta && parseFloat(valor_oferta) >= parseFloat(vr_unitario)) {
        errors.valor_oferta =
          "O valor da oferta deve ser menor que o valor unitário.";
      }
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }

    const { id_produto, id_estabelecimento } = this.props.produto;
    const grupoSelecionado =
      parseInt(id_grupo) ||
      (this.state.grupos[0] && this.state.grupos[0].id_grupo) ||
      null;

    const data = {
      id_produto,
      id_estabelecimento,
      id_grupo: grupoSelecionado,
      nome,
      vr_unitario: parseFloat(vr_unitario),
      codigo_controle,
      descricao,
      tag,
      status_produto,
    };

    if (isNaN(data.vr_unitario) || data.vr_unitario === undefined) {
      data.vr_unitario = 0.0;
    }

    if (this.state.imagem) {
      const imageUrl = await this.uploadImage(this.state.imagem);
      if (imageUrl) {
        data.imagem = imageUrl;
        console.log("imagem:", imageUrl);
      }
    } else if (this.props.produto.imagem !== "" && !this.state.imagem) {
      data.imagem = this.props.produto.imagem;
    }

    if (modo !== "oferta") {
      await this.props.saveProduto(id_produto, data);
      if (this.props.isProdutoNew) {
        this.setState({ tabIndex: 1 });
      }
    } else if (modo === "oferta") {
      const oferta = {
        inicio_oferta: inicio_oferta,
        fim_oferta: fim_oferta,
        valor_oferta: parseFloat(valor_oferta),
      };
      await this.props.saveProduto(id_produto, oferta, modo);
    }
  };

  selectComp = (complemento) => {
    this.setState({ selectedComp: complemento, isCompSelected: true });
  };

  componentBack = () => {
    this.setState({ selectedComp: {}, isCompSelected: false });
  };

  render() {
    const { produto, isProdutoNew } = this.props;
    const {
      complementos,
      grupos,
      tabIndex,
      inicio_oferta,
      fim_oferta,
      valor_oferta,
      isCompSelected,
      selectedComp,
      status_produto,
      id_produto,
      codigo_controle,
      nome,
      vr_unitario,
      tag,
      descricao,
    } = this.state;

    return (
      <Tabs
        className="container"
        selectedIndex={tabIndex}
        onSelect={(tabIndex) => this.setState({ tabIndex })}
      >
        {!isCompSelected ? (
          <>
            <TabList>
              <Tab>{isProdutoNew ? "Cadastro" : "Editar"}</Tab>
              <Tab disabled={isProdutoNew && true}>Complemento</Tab>
              <Tab disabled={isProdutoNew && true}>Oferta</Tab>
              <Tab disabled={isProdutoNew && true}>
                Disponibilidade (Dias da semana)
              </Tab>
            </TabList>
            <TabPanel>
              <div className="row col-md-9  mx-auto">
                <div className="row mt-4">
                  <div className="col-lg-6">
                    <h3>Produto - {isProdutoNew ? "Cadastro" : "Editar"}</h3>
                  </div>
                  {isProdutoNew && (
                    <div className="col-lg-6 d-grid">
                      <button
                        className="btn btn-vk"
                        onClick={() => this.props.redirectAdd()}
                      >
                        Novo Produto
                      </button>
                    </div>
                  )}
                  <div className="col-md-12">
                    <form onSubmit={this.handleSubmit}>
                      <div className="row">
                        <div className="col-sm-9">
                          {/* Campos do formulário */}
                          <div className="row">
                            <div className="col-sm-3">
                              <label
                                htmlFor="status"
                                className="form-check-label"
                              >
                                Status:
                              </label>
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  name="status_produto"
                                  checked={status_produto === 1}
                                  id="status"
                                  onChange={() =>
                                    this.setState((prevState) => ({
                                      status_produto:
                                        prevState.status_produto === 1 ? 0 : 1,
                                    }))
                                  }
                                  className="form-check-input mt-0"
                                  style={{ width: "30px", height: "30px" }}
                                />
                              </div>
                            </div>
                            <div className="col-sm-3">
                              <label className="form-label">Id.Produto:</label>
                              <input
                                type="text"
                                name="id_produto"
                                value={id_produto}
                                className="form-control input-sm"
                                placeholder=""
                                disabled
                              />
                            </div>
                            <div className="col-sm-5">
                              <label className="form-label">
                                Código Controle:
                              </label>
                              <input
                                type="text"
                                name="codigo_controle"
                                value={codigo_controle}
                                className="form-control input-sm"
                                placeholder=""
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-8">
                              <label className="form-label">Nome:</label>
                              <input
                                type="text"
                                name="nome"
                                value={nome}
                                className="form-control input-sm"
                                placeholder=""
                                onChange={this.handleChange}
                                required
                              />
                              {this.state.errors.nome && (
                                <div className="text-danger">
                                  {this.state.errors.nome}
                                </div>
                              )}
                            </div>
                            <div className="col-sm-3">
                              <label className="form-label">Vr.Unitário:</label>
                              <input
                                type="number"
                                step="0.01"
                                name="vr_unitario"
                                value={vr_unitario}
                                className="form-control input-sm"
                                placeholder=""
                                onChange={this.handleChange}
                                required
                              />
                              {this.state.errors.vr_unitario && (
                                <div className="text-danger">
                                  {this.state.errors.vr_unitario}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-6">
                              <label className="form-label">Grupo:</label>
                              <select
                                name="id_grupo"
                                className="form-select"
                                id="select_grupo"
                                value={this.state.id_grupo}
                                onChange={this.handleChange}
                              >
                                <option value="">Selecione...</option>
                                {grupos.map((grupo) => (
                                  <option
                                    key={grupo.id_grupo}
                                    value={grupo.id_grupo}
                                  >
                                    {grupo.descricao
                                      ? grupo.descricao
                                      : "Grupo não definido"}
                                  </option>
                                ))}
                              </select>
                              {this.state.errors.id_grupo && (
                                <div className="text-danger">
                                  {this.state.errors.id_grupo}
                                </div>
                              )}
                            </div>
                            <div className="col-sm-5">
                              <label className="form-label">Tag:</label>
                              <input
                                type="text"
                                name="tag"
                                value={tag}
                                className="form-control input-sm"
                                placeholder=""
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <label className="form-label">Imagem:</label>
                          <ImageInput
                            name="imagem"
                            defaultValue={produto.imagem}
                            onImageChange={this.handleImageChange}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <label className="form-label">Descrição:</label>
                          <textarea
                            name="descricao"
                            value={descricao}
                            className="form-control input-sm w-100"
                            placeholder=""
                            onChange={this.handleChange}
                            style={{
                              maxHeight: "150px",
                              minHeight: "40px",
                            }}
                          />
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-sm-3">
                          <Link
                            to="/produto"
                            className="btn btn-outline-secondary btn-lg w-100"
                          >
                            Voltar
                          </Link>
                        </div>
                        <div className="col-sm-9">
                          <button
                            type="submit"
                            className="btn btn-vk btn-lg w-100"
                            onClick={() => this.setState({ modo: "" })}
                          >
                            Salvar
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </TabPanel>
            {/* Abas Adicionais */}
            {!isProdutoNew && (
              <>
                <TabPanel>
                  <div className="container-fluid">
                    <CatComponent
                      idProduto={produto.id_produto}
                      idEstabelecimento={produto.id_estabelecimento}
                      complementos={complementos}
                      getProdutoComplemento={() => {
                        this.getCategoriaComplemento(
                          produto.id_produto,
                          produto.id_estabelecimento
                        );
                      }}
                      selectComp={(complemento) => this.selectComp(complemento)}
                    />
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="row justify-item-center">
                    <div className="col-md-6 mx-auto">
                      <h3>Produto - Oferta</h3>
                      <p />
                      <h3>Cadastrar nova oferta:</h3>
                      <form onSubmit={this.handleSubmit}>
                        <div className="row">
                          <div className="col-lg-6">
                            <label className="form-label">Data Início:</label>
                            <input
                              type="date"
                              name="inicio_oferta"
                              value={inicio_oferta}
                              className="form-control input-lg"
                              onChange={this.handleChange}
                            />
                            {this.state.errors.oferta_data && (
                              <div className="text-danger">
                                {this.state.errors.oferta_data}
                              </div>
                            )}
                          </div>
                          <div className="col-lg-6">
                            <label className="form-label">Data Fim:</label>
                            <input
                              type="date"
                              name="fim_oferta"
                              value={fim_oferta}
                              className="form-control input-lg"
                              onChange={this.handleChange}
                            />
                            {this.state.errors.oferta_data && (
                              <div className="text-danger">
                                {this.state.errors.oferta_data}
                              </div>
                            )}
                          </div>
                          <div className="col-lg-12">
                            <label className="form-label">
                              Valor da Oferta R$:
                            </label>
                            <input
                              type="number"
                              step="0.01"
                              name="valor_oferta"
                              value={valor_oferta}
                              className="form-control input-lg"
                              onChange={this.handleChange}
                            />
                            {this.state.errors.valor_oferta && (
                              <div className="text-danger">
                                {this.state.errors.valor_oferta}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-sm-3">
                            <Link
                              to="/produto"
                              className="btn btn-outline-secondary btn-lg w-100"
                            >
                              Voltar
                            </Link>
                          </div>
                          <div className="col-sm-9 d-grid">
                            <button
                              type="submit"
                              className="btn btn-vk btn-lg"
                              onClick={() => {
                                this.setState({ modo: "oferta" });
                              }}
                            >
                              Salvar
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <AgendamentoProduto produto={produto} tipo={"produto"} />
                </TabPanel>
              </>
            )}
          </>
        ) : (
          <div className="container-fluid">
            <AgendamentoProduto
              produto={produto}
              tipo={"complemento"}
              complemento={selectedComp}
              voltar={this.componentBack}
            />
          </div>
        )}
      </Tabs>
    );
  }
}

export default ProdutoForm;
