import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-hot-toast";
import EstabelecimentoContext from "../../context/EstabelecimentoContext";
import * as PrestAPI from "../../service/api/PrestAPI";
import { formatCNPJ, formatPhone } from "../../utils/formatters";
import HorariosAtendimento from "../../components/Horarios/Horarios";
import useCategorias from "../../hooks/Establecimentos/useCategoriasEstabelecimento";
import useUploadImage from "../../hooks/imagens/useUploadImage";

const formSchema = z.object({
  razao_social: z.string().nonempty("Razão social é obrigatória."),
  nome_fantasia: z.string().nonempty("Nome fantasia é obrigatória."),
  cnpj: z.string().nonempty("CNPJ é obrigatório."),
  email: z.string().email("Email inválido.").nonempty("Email é obrigatório."),
  telefone_contato: z.string().nonempty("Telefone de contato é obrigatório."),
  segmento: z.string().nonempty("Segmento é obrigatório."),
  bairro: z.string().nonempty("Bairro é obrigatório."),
  logradouro: z.string().nonempty("Logradouro é obrigatório."),
  cep: z.string().nonempty("CEP é obrigatório."),
  numero: z.string().nonempty("Número é obrigatório."),
  municipio: z.string().nonempty("Cidade é obrigatória."),
  uf: z.string().nonempty("UF é obrigatória."),
});

const FormCadastro = () => {
  const { estabelecimento } = useContext(EstabelecimentoContext);
  const { categorias } = useCategorias();
  const { uploadImage } = useUploadImage();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      razao_social: estabelecimento.razao_social || "",
      nome_fantasia: estabelecimento.nome_fantasia || "",
      cnpj: formatCNPJ(estabelecimento.cnpj || ""),
      email: estabelecimento.email || "",
      telefone_contato: formatPhone(estabelecimento.telefone_contato || ""),
      segmento: estabelecimento.segmento || "",
      ie: estabelecimento.ie || "",
      bairro: estabelecimento.bairro || "",
      logradouro: estabelecimento.logradouro || "",
      complemento: estabelecimento.complemento || "",
      cep: estabelecimento.cep || "",
      numero: estabelecimento.numero || "",
      municipio: estabelecimento.municipio || "",
      uf: estabelecimento.uf || "",
    },
  });

  const onSubmit = async (formData) => {
    console.log("estabelecimento:", formData);
  };

  return (
    <div className="container-fluid">
      <h3>Meus Dados</h3>
      <form className="row g-3" onSubmit={handleSubmit(onSubmit)}>
        <div className="col-md-6">
          <label className="form-label">Razão social</label>
          <input
            className="form-control"
            type="text"
            placeholder="Digite a razão social"
            {...register("razao_social")}
            disabled={!!estabelecimento.razao_social}
          />
          {errors.razao_social && (
            <span className="text-danger">{errors.razao_social.message}</span>
          )}
        </div>
        <div className="col-md-6">
          <label className="form-label">Nome fantasia</label>
          <input
            className="form-control"
            label="Nome fantasia"
            type="text"
            placeholder="Digite o nome fantasia"
            {...register("nome_fantasia")}
          />
          {errors.nome_fantasia && (
            <span className="text-danger">{errors.nome_fantasia.message}</span>
          )}
        </div>
        <div className="col-md-4">
          <label className="form-label">CNPJ</label>
          <input
            className="form-control"
            type="text"
            placeholder="Digite o CNPJ"
            {...register("cnpj")}
            disabled={!!estabelecimento.cnpj}
          />
          {errors.cnpj && (
            <span className="text-danger">{errors.cnpj.message}</span>
          )}
        </div>
        <div className="col-md-4">
          <label className="form-label">Inscrição Estadual</label>
          <input
            className="form-control"
            type="text"
            placeholder="Digite a inscrição estadual"
            {...register("ie")}
            disabled={!!estabelecimento.ie}
          />
        </div>

        <div className="col-md-4">
          <label htmlFor="segmento" className="form-label">
            Segmento
          </label>
          <select
            className="form-select"
            id="segmento"
            {...register("segmento")}
            disabled={!!estabelecimento.segmento}
            required
          >
            <option value="">Selecione o segmento</option>
            {categorias.map((categoria, index) => (
              <option key={index} value={categoria.nome}>
                {categoria.nome}
              </option>
            ))}
          </select>
          {errors.segmento && (
            <span className="text-danger">{errors.segmento.message}</span>
          )}
        </div>
        <div className="col-md-4">
          <label className="form-label">Email</label>
          <input
            className="form-control"
            type="email"
            placeholder="Digite o email"
            disabled={!!estabelecimento.email}
            {...register("email")}
          />
          {errors.email && (
            <span className="text-danger">{errors.email.message}</span>
          )}
        </div>
        <div className="col-md-4">
          <label className="form-label">Contato</label>
          <input
            className="form-control"
            type="text"
            placeholder="Digite o nome do contato"
            {...register("contato")}
          />
        </div>
        <div className="col-md-4">
          <label className="form-label">Telefone do contato</label>
          <input
            className="form-control"
            type="text"
            placeholder="(99) 99999-9999"
            {...register("telefone_contato")}
          />
          {errors.telefone_contato && (
            <span className="text-danger">
              {errors.telefone_contato.message}
            </span>
          )}
        </div>
        <div className="col-md-6">
          <label className="form-label">Bairro</label>
          <input
            className="form-control"
            type="text"
            disabled={!!estabelecimento.bairro}
            placeholder="Digite o bairro"
            {...register("bairro")}
          />
          {errors.bairro && (
            <span className="text-danger">{errors.bairro.message}</span>
          )}
        </div>
        <div className="col-md-6">
          <label className="form-label">Logradouro</label>
          <input
            className="form-control"
            type="text"
            disabled={!!estabelecimento.logradouro}
            placeholder="Digite o logradouro"
            {...register("logradouro")}
          />
          {errors.logradouro && (
            <span className="text-danger">{errors.logradouro.message}</span>
          )}
        </div>
        <div className="col-md-8">
          <label className="form-label">Complemento</label>
          <input
            className="form-control"
            type="text"
            placeholder="Digite o complemento"
            {...register("complemento")}
          />
        </div>
        <div className="col-md-4">
          <label className="form-label">CEP</label>
          <input
            className="form-control"
            type="text"
            disabled={!!estabelecimento.cep}
            placeholder="Digite o CEP"
            {...register("cep")}
          />
          {errors.cep && (
            <span className="text-danger">{errors.cep.message}</span>
          )}
        </div>
        <div className="col-md-2">
          <label className="form-label">Número</label>
          <input
            className="form-control"
            type="text"
            disabled={!!estabelecimento.numero}
            placeholder="Digite o número"
            {...register("numero")}
          />
          {errors.numero && (
            <span className="text-danger">{errors.numero.message}</span>
          )}
        </div>
        <div className="col-md-4">
          <label className="form-label">Cidade</label>
          <input
            className="form-control"
            type="text"
            disabled={!!estabelecimento.municipio}
            placeholder="Digite a cidade"
            {...register("municipio")}
          />
          {errors.municipio && (
            <span className="text-danger">{errors.municipio.message}</span>
          )}
        </div>
        <div className="col-md-2">
          <label className="form-label">UF</label>
          <input
            className="form-control"
            type="text"
            disabled={!!estabelecimento.uf}
            placeholder="Digite a UF"
            {...register("uf")}
          />
          {errors.uf && (
            <span className="text-danger">{errors.uf.message}</span>
          )}
        </div>

        {/* Horarios de Atendimento */}
        <div className="row mt-2 border-top">
          <h5 className="mt-2 text-center">Horários de Atendimento</h5>
          <HorariosAtendimento horarios={estabelecimento.horarios} />
        </div>

        <div className="mt-2">
          <button className="btn btn-vk" type="submit">
            Salvar
          </button>
        </div>
      </form>
    </div>
  );
};

export default FormCadastro;
