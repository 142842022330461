import CryptoJS from "crypto-js";

export const getStorage = () => {
  const loggedDados = localStorage.getItem("Estab");

  if (!loggedDados) {
    console.log("Nenhum dado encontrado no local storage.");
    return "";
  }

  try {
    const decryptDados = CryptoJS.AES.decrypt(
      loggedDados,
      process.env.REACT_APP_ENCRYPT_PASS
    );
    const decryptedDados = JSON.parse(decryptDados.toString(CryptoJS.enc.Utf8));
    return decryptedDados;
  } catch (error) {
    console.error("Erro ao descriptografar os dados", error);
    return "";
  }
};
