import React, { useState } from "react";
import { Pencil, Trash3 } from "react-bootstrap-icons";

const Usuarios = () => {
  const [isNewUser, setIsNewUser] = useState(false);
  return (
    <div className="container">
      <h1 className="fs-3">Usuários</h1>
      <div>
        <button className="btn btn-vk" onClick={() => setIsNewUser(true)}>
          Novo Usuário
        </button>
        {isNewUser && (
          <form className="mt-2">
            <div className="col-lg-6">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input type="email" id="email" className="form-control" />
            </div>
            <div className="col-lg-6">
              <label htmlFor="senha" className="form-label">
                Senha
              </label>
              <input type="password" id="password" className="form-control" />
            </div>
            <div className="col-lg-6">
              <label htmlFor="senha" className="form-label">
                Confirme a senha
              </label>
              <input type="password" id="password" className="form-control" />
            </div>
            <div className="mt-2">
              <button className="btn btn-vk">Salvar</button>
            </div>
          </form>
        )}

        <div className="row table-responsive mt-2">
          <table className="table table-bordered table-striped">
            <thead>
              <tr>
                <th>Nome</th>
                <th>Usuário</th>
                <th>Senha</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td className="d-flex justify-content-evenly">
                  <button className="btn btn-outline-light" title="Editar">
                    <Pencil color="#FF5722" />
                  </button>

                  <button className="btn btn-outline-light" title="Excluir">
                    <Trash3 color="#FF5722" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Usuarios;
