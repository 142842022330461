import React, { Component } from "react";
import ComponentItem from "./ComponentItem";
import PropTypes from "prop-types";
import { Accordion, Card } from "react-bootstrap";

import NewCategoriaComplement from "./NewCategoriaComplement";

import "./styles.css";
import CategoriaComplementList from "./CategoriaComplementList";

class CatComponent extends Component {
  static propTypes = {
    idProduto: PropTypes.number,
    idEstabelecimento: PropTypes.number,
    complementos: PropTypes.array,
    getProdutoComplemento: PropTypes.func,
    selectComp: PropTypes.func,
  };

  state = {
    ocultaCategoria: true,
    ocultaComplementoItem: true,
    isModalVisible: false,
    statusCat: "Ativo",
    statusComp: "Ativo",
    activeKeys: [],
  };

  handleAccordionToggle = (id_grupo) => {
    this.setState((prevState) => {
      const { activeKeys } = prevState;
      const isActive = activeKeys.includes(id_grupo);

      return {
        activeKeys: isActive
          ? activeKeys.filter((key) => key !== id_grupo)
          : [...activeKeys, id_grupo],
      };
    });
  };

  exibeCategoriaComponent = () => {
    this.setState({ ocultaCategoria: false });
  };

  ocultaCategoriaComponent = () => {
    this.setState({ ocultaCategoria: true });
  };

  componentDidMount() {
    this.setState({ statusCat: "Ativo", statusComp: "Ativo" });
  }

  render() {
    const {
      idProduto,
      idEstabelecimento,
      getProdutoComplemento,
      complementos,
    } = this.props;
    const { ocultaCategoria, statusCat, statusComp, activeKeys } = this.state;

    return (
      <div className="container-fluid">
        <div className="row justify-content-between align-items-center mt-4 mb-3">
          <div className="col-sm-3 align-content-center">
            <button
              onClick={
                ocultaCategoria
                  ? this.exibeCategoriaComponent
                  : this.ocultaCategoriaComponent
              }
              className={` ${
                ocultaCategoria ? "btn btn-vk w-100" : "btn-outline-vk w-100"
              } `}
              style={ocultaCategoria ? { outline: "none" } : {}}
            >
              {ocultaCategoria ? "Adicionar grupo (Título)" : "X"}
            </button>
          </div>
          <div className="col-sm-4 row">
            <label className="col-form-label col text-end">Status Grupo:</label>
            <div className="col align-content-center">
              <select
                className="form-select input-sm"
                id="statusFiltro"
                onChange={(e) => {
                  this.setState({ statusCat: e.target.value });
                }}
              >
                <option value="Ativo">Ativo</option>
                <option value="Inativo">Inativo</option>
                <option value="Todos">Todos</option>
              </select>
            </div>
          </div>
          <div className="col-sm-4 row">
            <label className="col-form-label col text-end">
              Status Complemento:
            </label>
            <div className="col align-content-center">
              <select
                className="form-select input-sm"
                id="statusFiltro"
                onChange={(e) => {
                  this.setState({ statusComp: e.target.value });
                }}
              >
                <option value="Ativo">Ativo</option>
                <option value="Inativo">Inativo</option>
                <option value="Todos">Todos</option>
              </select>
            </div>
          </div>
        </div>

        {!ocultaCategoria && (
          <NewCategoriaComplement
            idEstabelecimento={idEstabelecimento}
            idProduto={idProduto}
            ocultaCategoriaComponent={this.ocultaCategoriaComponent}
            getCatComplementos={getProdutoComplemento}
          />
        )}

        <Accordion activeKey={activeKeys}>
          {complementos &&
            complementos
              .filter((categoria) => {
                if (statusCat === "Ativo") {
                  return categoria.status_categoria === 1;
                } else if (statusCat === "Inativo") {
                  return categoria.status_categoria === 0;
                }
                return true;
              })
              .map((categoria) => (
                <div key={categoria.id_categoria_complemento}>
                  <Card.Header
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      this.handleAccordionToggle(
                        categoria.id_categoria_complemento
                      )
                    }
                  >
                    <CategoriaComplementList
                      categoria={categoria}
                      getCatComplementos={getProdutoComplemento}
                    />
                  </Card.Header>

                  <Accordion.Collapse
                    eventKey={categoria.id_categoria_complemento}
                    in={activeKeys.includes(categoria.id_categoria_complemento)}
                  >
                    <Card.Body className="mx-1 rounded rounded-bottom">
                      {categoria.itens &&
                        categoria.itens
                          .filter((item) => {
                            if (statusComp === "Ativo") {
                              return item.status_complemento === 1;
                            } else if (statusComp === "Inativo") {
                              return item.status_complemento === 0;
                            }
                            return true;
                          })
                          .map((item) => (
                            <ComponentItem
                              key={item.id_complemento}
                              ocultar={true}
                              item={item}
                              getCatComplementos={getProdutoComplemento}
                              selectComp={this.props.selectComp}
                            />
                          ))}
                    </Card.Body>
                  </Accordion.Collapse>
                </div>
              ))}
        </Accordion>
      </div>
    );
  }
}

export default CatComponent;
