import React, { useState } from "react";
import { toast } from "react-hot-toast";

const HorariosAtendimento = ({ horarios = [] }) => {
  const [disponibilidadePorDia, setDisponibilidadePorDia] = useState({
    domingo: { horaInicio: "00:00", horaFim: "00:00" },
    segunda: { horaInicio: "00:00", horaFim: "00:00" },
    terca: { horaInicio: "00:00", horaFim: "00:00" },
    quarta: { horaInicio: "00:00", horaFim: "00:00" },
    quinta: { horaInicio: "00:00", horaFim: "00:00" },
    sexta: { horaInicio: "00:00", horaFim: "00:00" },
    sabado: { horaInicio: "00:00", horaFim: "00:00" },
  });

  const [diasSelecionados, setDiasSelecionados] = useState({
    domingo: false,
    segunda: false,
    terca: false,
    quarta: false,
    quinta: false,
    sexta: false,
    sabado: false,
  });

  const diaMappingDisplay = {
    domingo: "domingo",
    segunda: "segunda",
    terca: "terça",
    quarta: "quarta",
    quinta: "quinta",
    sexta: "sexta",
    sabado: "sábado",
  };

  const handleChangeDias = (dia) => {
    setDiasSelecionados((prevDias) => ({
      ...prevDias,
      [dia]: !prevDias[dia],
    }));
  };

  const handleSaveHorarios = () => {
    const selectedDays = Object.keys(diasSelecionados).filter(
      (dia) => diasSelecionados[dia]
    );

    if (selectedDays.length === 0) {
      toast.error("Você precisa selecionar os dias da semana");
      return;
    }

    const horarios = selectedDays.reduce((acc, dia) => {
      const diaFormatado = `horario_${dia}`;
      acc[
        diaFormatado
      ] = `${disponibilidadePorDia[dia].horaInicio}|${disponibilidadePorDia[dia].horaFim}`;
      return acc;
    }, {});

    console.log("Dados enviados:", horarios);
    toast.success("Horários salvos com sucesso!");
  };

  return (
    <div className="container-fluid py-3 rounded overflow-x-hidden overflow-y-auto z-2 m-0">
      <div className="row g-2 mb-5">
        <div className="row g-2">
          <div className="col-sm-6">
            <label className="form-label col w-100">Dias da semana:</label>
            <div className="btn-group" role="group">
              {Object.keys(diasSelecionados).map((dia, index) => (
                <label
                  key={index}
                  className={`btn btn-outline-vk fs-5 btn-md ${
                    diasSelecionados[dia] ? "active" : ""
                  }`}
                >
                  <input
                    type="checkbox"
                    className="btn-check"
                    checked={diasSelecionados[dia]}
                    onChange={() => handleChangeDias(dia)}
                  />
                  {diaMappingDisplay[dia].charAt(0).toUpperCase() +
                    diaMappingDisplay[dia].slice(1)}{" "}
              
                </label>
              ))}
            </div>
          </div>
        </div>

        {Object.keys(diasSelecionados).map(
          (dia) =>
            diasSelecionados[dia] && (
              <div key={dia}>
                <h5 className="text-capitalize">{diaMappingDisplay[dia]}</h5>
                <div className="row">
                  <div className="col-sm-6">
                    <label htmlFor="horaInicio" className="form-label">
                      Hora Início:
                    </label>
                    <input
                      type="time"
                      className="form-control input-md"
                      name="horaInicio"
                      value={disponibilidadePorDia[dia].horaInicio}
                      onChange={(e) => {
                        const newDisponibilidade = {
                          ...disponibilidadePorDia[dia],
                          horaInicio: e.target.value,
                        };
                        setDisponibilidadePorDia((prev) => ({
                          ...prev,
                          [dia]: newDisponibilidade,
                        }));
                      }}
                      required
                    />
                  </div>
                  <div className="col-sm-6">
                    <label htmlFor="horaFim" className="form-label">
                      Hora Fim:
                    </label>
                    <input
                      type="time"
                      className="form-control input-md"
                      name="horaFim"
                      value={disponibilidadePorDia[dia].horaFim}
                      onChange={(e) => {
                        const newDisponibilidade = {
                          ...disponibilidadePorDia[dia],
                          horaFim: e.target.value,
                        };
                        setDisponibilidadePorDia((prev) => ({
                          ...prev,
                          [dia]: newDisponibilidade,
                        }));
                      }}
                      required
                    />
                  </div>
                </div>
                <hr />
              </div>
            )
        )}
        <div className="col-lg-3">
          <button className="btn btn-vk" onClick={handleSaveHorarios}>
            Adicionar Horários
          </button>
        </div>
      </div>
    </div>
  );
};

export default HorariosAtendimento;
