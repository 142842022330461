import { useState, useContext } from "react";
import CryptoJS from "crypto-js";
import toast from "react-hot-toast";
import * as PrestAPI from "../../service/api/PrestAPI";
import EstabelecimentoContext from "../../context/EstabelecimentoContext";

export const useAuthEstabelecimento = () => {
  const [estabelecimento, setEstabelecimento] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { setEstabelecimento: setContextEstabelecimento } = useContext(
    EstabelecimentoContext
  );

  const login = async (data) => {
    try {
      setLoading(true);
      const result = await PrestAPI.getToken("panel", data);
      if (result && result.value) {
        const storeData = {
          ...result.value,
          username: data.username,
          password: data.password,
        };
        console.log(storeData);
        await getEstabelecimento(storeData);
      }
    } catch (error) {
      toast.error("Usuário ou senha não conferem.", { id: "erroLogin" });
      setError(error);
      localStorage.clear();
    } finally {
      setLoading(false);
    }
  };

  const getEstabelecimento = async (data) => {
    try {
      const result = await PrestAPI.get(`estabelecimento/${data.store_uid}`);
      if (!result.id_estabelecimento) {
        toast.error("Estabelecimento não encontrado");
        localStorage.clear();
        return;
      }

      const encryptedData = CryptoJS.AES.encrypt(
        JSON.stringify({
          uid: data.username,
          ticket: data.password,
          role: data.role,
        }),
        process.env.REACT_APP_ENCRYPT_PASS
      ).toString();

      localStorage.setItem("Estab", encryptedData);
      setContextEstabelecimento(result);
      setEstabelecimento(result);
    } catch (error) {
      toast.error("Erro ao obter estabelecimento.");
      setError(error);
    }
  };

  const logout = () => {
    setEstabelecimento(null);
    localStorage.removeItem("Estab");
    localStorage.removeItem("token");
  };

  return {
    estabelecimento,
    loading,
    error,
    login,
    getEstabelecimento,
    logout,
  };
};
