import React, { createContext, useState, useContext } from "react";

const EstabelecimentoContext = createContext();

export const EstabelecimentoProvider = ({ children }) => {
  const [estabelecimento, setEstabelecimento] = useState(null);

  return (
    <EstabelecimentoContext.Provider value={{ estabelecimento, setEstabelecimento }}>
      {children}
    </EstabelecimentoContext.Provider>
  );
};
export const useEstabelecimentoContext = () => {
  return useContext(EstabelecimentoContext);
};

export default EstabelecimentoContext;
